const Github = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.16667 8.00008C3.16667 7.18748 3.3672 6.42174 3.72147 5.74961L3.20105 4.03601C3.02643 3.46109 3.64175 2.96673 4.16556 3.26111L5.37516 3.94091C6.13101 3.45114 7.03227 3.16675 8.00001 3.16675C8.96841 3.16675 9.87027 3.45154 10.6264 3.94193L11.8416 3.26081C12.366 2.96684 12.981 3.46264 12.805 4.03753L12.28 5.75233C12.6333 6.4238 12.8333 7.18861 12.8333 8.00008C12.8333 10.6695 10.6694 12.8334 8.00001 12.8334C5.33063 12.8334 3.16667 10.6695 3.16667 8.00008Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Github;
