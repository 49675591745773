import React, { useMemo } from 'react';
import { type ChainflipNetwork } from '@chainflip/sdk/swap';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { getChainflipNetwork } from '@/shared/utils';

const defaultSolanaRpcUrls = {
  mainnet: clusterApiUrl(WalletAdapterNetwork.Mainnet),
  perseverance: clusterApiUrl(WalletAdapterNetwork.Devnet),
  sisyphos: clusterApiUrl(WalletAdapterNetwork.Devnet),
  backspin: clusterApiUrl(WalletAdapterNetwork.Testnet),
  unknown: clusterApiUrl(WalletAdapterNetwork.Testnet),
} satisfies Record<ChainflipNetwork | 'unknown', string>;

const endpoint =
  process.env.NEXT_PUBLIC_SOLANA_RPC_URL || defaultSolanaRpcUrls[getChainflipNetwork()];

export const SolanaProvider = ({ children }: { children: React.ReactNode }) => {
  // since major wallets support Wallet Standard, we dont need any custom adapter
  // https://solana.com/de/developers/courses/intro-to-solana/interact-with-wallets
  const wallets = useMemo(() => [], []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
