const DotsVertical = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.66668 8.00001C8.66668 8.36821 8.36821 8.66668 8.00001 8.66668C7.63181 8.66668 7.33334 8.36821 7.33334 8.00001C7.33334 7.63181 7.63181 7.33334 8.00001 7.33334C8.36821 7.33334 8.66668 7.63181 8.66668 8.00001Z"
      fill="currentColor"
    />
    <path
      d="M8.66668 5.33332C8.66668 5.70151 8.36821 5.99999 8.00001 5.99999C7.63181 5.99999 7.33334 5.70151 7.33334 5.33332C7.33334 4.96514 7.63181 4.66666 8.00001 4.66666C8.36821 4.66666 8.66668 4.96514 8.66668 5.33332Z"
      fill="currentColor"
    />
    <path
      d="M8.66668 10.6667C8.66668 11.0349 8.36821 11.3333 8.00001 11.3333C7.63181 11.3333 7.33334 11.0349 7.33334 10.6667C7.33334 10.2985 7.63181 10 8.00001 10C8.36821 10 8.66668 10.2985 8.66668 10.6667Z"
      fill="currentColor"
    />
  </svg>
);

export default DotsVertical;
