import { useRouter } from 'next/router';
import Button from './flip-ui-kit/Button';

const NotFound = (): JSX.Element => {
  const router = useRouter();

  return (
    <div className="flex h-[calc(100vh_-_260px)] items-center justify-center text-center">
      <div className="flex flex-col items-center justify-around space-y-12 rounded-lg p-6">
        <h1 className="text-7xl font-bold text-cf-light-4">404!</h1>
        <p className=" text-lg font-light text-cf-orange-1">Results not found</p>
        <Button onClick={() => router.push('/')}>Back to home</Button>
      </div>
    </div>
  );
};

export default NotFound;
