import { useRef } from 'react';
import { useQuery } from '@tanstack/react-query';

export const RefreshOnVersionChange = ({
  refreshInterval = 60_000,
  baseAddress,
}: {
  refreshInterval?: number;
  baseAddress: string;
}) => {
  const hashRef = useRef<string>();

  useQuery({
    refetchInterval: refreshInterval,
    queryKey: ['appVersionHash'],
    queryFn: async () => {
      const response = await fetch(new URL('/version', baseAddress).toString(), {
        cache: 'no-cache',
      });

      if (!response.ok) return hashRef.current ?? '';

      const hash = await response.text();
      if (!hashRef.current) {
        hashRef.current = hash;
      }
      if (hash !== hashRef.current) {
        window.location.reload();
      }
      return hash;
    },
  });

  return null;
};
