import { gql } from '@/shared/graphql/generated';

export const getCachePoolsQuery = gql(/* GraphQL */ `
  query GetCachePools($baseAsset: ChainflipAsset) {
    pools: allPools(condition: { baseAsset: $baseAsset }) {
      nodes {
        baseAsset
        quoteAsset
        buyPrice
        rangeOrderPrice
        baseLiquidityAmount
        quoteLiquidityAmount
        liquidityFeeHundredthPips
      }
    }
  }
`);

export const getCachePoolsWithOrdersQuery = gql(/* GraphQL */ `
  query GetCachePoolsWithOrder($baseAsset: ChainflipAsset) {
    pools: allPools(condition: { baseAsset: $baseAsset }) {
      nodes {
        baseAsset
        quoteAsset
        buyPrice
        rangeOrderPrice
        baseLiquidityAmount
        quoteLiquidityAmount
        liquidityFeeHundredthPips
        openOrders
      }
    }
  }
`);

export const getPoolTableDataQuery = gql(/* GraphQL */ `
  query GetPoolTableData($date24HoursAgo: Datetime!, $date48HoursAgo: Datetime!) {
    currentDayAggregates: allPoolSwaps(
      filter: { assetSwappedBlockTimestamp: { greaterThanOrEqualTo: $date24HoursAgo } }
    ) {
      groupedAggregates(groupBy: [FROM_ASSET, TO_ASSET]) {
        fromAssetToAsset: keys
        sum {
          toValueUsd
          liquidityFeeValueUsd
        }
      }
    }
    previousDayAggregates: allPoolSwaps(
      filter: {
        assetSwappedBlockTimestamp: {
          greaterThanOrEqualTo: $date48HoursAgo
          lessThan: $date24HoursAgo
        }
      }
    ) {
      groupedAggregates(groupBy: [FROM_ASSET, TO_ASSET]) {
        fromAssetToAsset: keys
        sum {
          toValueUsd
          liquidityFeeValueUsd
        }
      }
    }
  }
`);

export const getPoolSwapsQuery = gql(/* GraphQL */ `
  query GetPoolLastPrices(
    $baseAsset: ChainflipAsset!
    $latestSwapTimestamp: Datetime!
    $earliestSwapTimestamp: Datetime!
  ) {
    latestSwap: allSwaps(
      first: 1
      orderBy: SWAP_EXECUTED_EVENT_ID_DESC
      filter: {
        swapExecutedBlockTimestamp: { lessThanOrEqualTo: $latestSwapTimestamp }
        swapInputAmount: { greaterThan: "0" }
        swapOutputAmount: { greaterThan: "0" }
        swapOutputValueUsd: { greaterThan: "0" }
        type: { in: [SWAP, PRINCIPAL, GAS] }
        or: [
          { sourceAsset: { equalTo: $baseAsset } }
          { destinationAsset: { equalTo: $baseAsset } }
        ]
      }
    ) {
      nodes {
        id
        nativeId
        sourceAsset
        destinationAsset
        swapInputAmount
        swapInputValueUsd
        swapOutputAmount
        swapOutputValueUsd
        intermediateAmount
        intermediateValueUsd
      }
    }
    earliestSwap: allSwaps(
      first: 1
      orderBy: SWAP_EXECUTED_EVENT_ID_DESC
      filter: {
        swapExecutedBlockTimestamp: { lessThanOrEqualTo: $earliestSwapTimestamp }
        swapInputAmount: { greaterThan: "0" }
        swapOutputAmount: { greaterThan: "0" }
        swapOutputValueUsd: { greaterThan: "0" }
        type: { in: [SWAP, PRINCIPAL, GAS] }
        or: [
          { sourceAsset: { equalTo: $baseAsset } }
          { destinationAsset: { equalTo: $baseAsset } }
        ]
      }
    ) {
      nodes {
        id
        nativeId
        sourceAsset
        destinationAsset
        swapInputAmount
        swapInputValueUsd
        swapOutputAmount
        swapOutputValueUsd
        intermediateAmount
        intermediateValueUsd
      }
    }
  }
`);
