const TimeBoost = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M11.3513 4.63484C11.3513 4.27417 11.0589 3.98178 10.6982 3.98178H9.98803L11.315 2.24647V3.365C11.315 3.72568 11.6074 4.01806 11.9681 4.01806H12.6783L11.3513 5.75337V4.63484Z"
      stroke="currentColor"
      strokeWidth="1.30612"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.18018 5.84668V8.01335L7.18018 8.84668"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3658 9.92103C12.0453 10.6448 11.5496 11.2773 10.9235 11.7614C10.2973 12.2456 9.56047 12.5663 8.7794 12.6944C7.99832 12.8226 7.19764 12.7542 6.44961 12.4954C5.70159 12.2367 5.02978 11.7957 4.49483 11.2123C3.95988 10.629 3.57863 9.92155 3.3855 9.15396C3.19237 8.38637 3.19344 7.58277 3.38862 6.8157C3.58379 6.04862 3.96692 5.34223 4.50342 4.76029C5.03992 4.17834 5.7129 3.73917 6.46161 3.48241"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export default TimeBoost;
