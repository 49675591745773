const Slice = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M8 12.844C10.6694 12.844 12.8333 10.68 12.8333 8.01064C12.8333 5.34126 10.6694 3.17731 8 3.17731C5.33062 3.17731 3.16666 5.34126 3.16666 8.01064C3.16666 10.68 5.33062 12.844 8 12.844Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83333 3.34393V6.84393C7.83333 7.58033 8.43026 8.17727 9.16666 8.17727H12.6667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Slice;
