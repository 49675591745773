const TimeBoost = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_2212_1186)">
      <path
        d="M17.026 6.95356C17.026 6.41258 16.5875 5.97403 16.0465 5.97403H14.9812L16.9716 3.37125V5.04893C16.9716 5.5899 17.4101 6.02845 17.9511 6.02845H19.0164L17.026 8.63124V6.95356Z"
        stroke="currentColor"
        strokeWidth="1.95905"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2691 8.77106V12.0208L10.7692 13.2708"
        stroke="currentColor"
        strokeWidth="1.4999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5473 14.8822C18.0666 15.9677 17.3231 16.9164 16.3839 17.6427C15.4448 18.3689 14.3396 18.8498 13.168 19.042C11.9965 19.2342 10.7956 19.1316 9.6736 18.7435C8.55164 18.3554 7.544 17.694 6.74163 16.819C5.93926 15.944 5.36743 14.883 5.07776 13.7317C4.78808 12.5804 4.78969 11.3751 5.08242 10.2245C5.37516 9.074 5.94982 8.01449 6.75451 7.14163C7.55921 6.26878 8.56861 5.61006 9.6916 5.22495"
        stroke="currentColor"
        strokeWidth="1.4999"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2212_1186">
        <rect
          width="23.9984"
          height="23.9984"
          fill="currentColor"
          transform="translate(0 0.00164795)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default TimeBoost;
