const Discord = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.94288 4.92251L3.2912 9.74492C3.21637 10.2987 3.4948 10.8404 3.98868 11.1019L5.99998 12.1667L6.66664 10.5C6.66664 10.5 7.10414 10.8333 7.99994 10.8333C8.89581 10.8333 9.33328 10.5 9.33328 10.5L9.99994 12.1667L12.0113 11.1019C12.5051 10.8404 12.7836 10.2987 12.7087 9.74492L12.0571 4.92251C12.0223 4.66492 11.8409 4.45104 11.5925 4.3746L9.83328 3.83334V4.16668C9.83328 4.53487 9.53481 4.83334 9.16661 4.83334H6.83328C6.46512 4.83334 6.16664 4.53487 6.16664 4.16668V3.83334L4.40749 4.3746C4.15904 4.45104 3.97769 4.66492 3.94288 4.92251Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00001 7.99999C7.00001 8.18406 6.85074 8.33332 6.66668 8.33332C6.48258 8.33332 6.33334 8.18406 6.33334 7.99999C6.33334 7.81592 6.48258 7.66666 6.66668 7.66666C6.85074 7.66666 7.00001 7.81592 7.00001 7.99999Z"
      stroke="currentColor"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.66667 7.99999C9.66667 8.18406 9.5174 8.33332 9.33333 8.33332C9.14927 8.33332 9 8.18406 9 7.99999C9 7.81592 9.14927 7.66666 9.33333 7.66666C9.5174 7.66666 9.66667 7.81592 9.66667 7.99999Z"
      stroke="currentColor"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Discord;
