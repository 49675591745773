import { type ChainflipAsset } from '@chainflip/utils/chainflip';
import { isTestnet } from '@/shared/featureFlags';
import { NATIVE_TOKEN_ADDRESS } from './constants';
import mainnet from './mainnet';
import testnet from './testnet';
import { type ChainData, chainById, type ChainId, type ChainflipChainData } from '../chains';

export interface Token {
  chain: ChainData;
  address: string;
  name: string;
  symbol: string;
  /** the symbol from the deployed contract */
  canonicalSymbol?: string;
  decimals: number;
  logo?: string;
  chainflipId?: ChainflipAsset;
}

export interface ChainflipToken extends Token {
  chain: ChainflipChainData;
  chainflipId: ChainflipAsset;
}

export function isChainflipTokenOrChain(token: Token | undefined): token is ChainflipToken;
export function isChainflipTokenOrChain(chain: ChainData | undefined): chain is ChainflipChainData;
export function isChainflipTokenOrChain(obj: Token | ChainData | undefined) {
  if (!obj) return false;
  return 'chainflipId' in obj;
}

export const tokens = isTestnet ? testnet : mainnet;
export type SupportedTokenSymbol = (typeof tokens)[number]['symbol'];

export const tokenByAddress = (
  chainId: ChainId,
  address: string,
  logo: string | undefined,
  fallbackInformation: Pick<Token, 'name' | 'symbol' | 'decimals'>,
): Token => {
  const knownToken = tokens.find(
    (token) => token.chain.id === chainId && token.address.toLowerCase() === address.toLowerCase(),
  );
  if (knownToken) return knownToken;

  const chain = chainById(chainId);
  if (!chain) {
    throw new Error(`chain "${chainId}" not found`);
  }

  return {
    chain,
    address,
    logo,
    ...fallbackInformation,
  };
};

export * from './mainnet';
export * from './testnet';

export const isWatchable = (token: Token) =>
  token.chain.id.startsWith('evm-') && token.address !== NATIVE_TOKEN_ADDRESS;
