const Chat = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66101 9.67339C8.52433 9.67339 10.1553 8.66372 10.1553 6.42001C10.1553 4.17632 8.52433 3.16666 6.66101 3.16666C4.79769 3.16666 3.16667 4.17632 3.16667 6.42001C3.16667 7.24412 3.3867 7.90172 3.75747 8.40399C3.89196 8.58619 3.86362 9.01086 3.75614 9.21019C3.49891 9.68726 3.98492 10.21 4.51318 10.0889C4.9086 9.99826 5.32336 9.87559 5.68633 9.71106C5.81922 9.65079 5.96595 9.62652 6.11094 9.64292C6.29105 9.66326 6.47487 9.67339 6.66101 9.67339Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5392 6.68425C12.3649 6.47013 12.0499 6.43791 11.8358 6.61228C11.6217 6.78665 11.5894 7.10159 11.7638 7.31572L12.5392 6.68425ZM7.3202 11.2629C7.0972 11.1001 6.7844 11.1487 6.6215 11.3717C6.4586 11.5947 6.50728 11.9075 6.73026 12.0704L7.3202 11.2629ZM13.3219 9.08665C13.3219 8.10559 13.0397 7.29885 12.5392 6.68425L11.7638 7.31572C12.1007 7.72939 12.3219 8.30479 12.3219 9.08665H13.3219ZM12.6334 11.3676C13.0758 10.7683 13.3219 10.0031 13.3219 9.08665H12.3219C12.3219 9.81845 12.128 10.3685 11.8289 10.7737L12.6334 11.3676ZM10.0959 12.8331C10.5003 13.0165 10.9502 13.1481 11.3637 13.2429L11.5871 12.2682C11.2098 12.1817 10.8302 12.0681 10.5087 11.9223L10.0959 12.8331ZM9.3276 12.8401C9.53173 12.8401 9.73433 12.829 9.93386 12.8064L9.82146 11.8127C9.6608 11.8309 9.49573 11.8401 9.3276 11.8401V12.8401ZM6.73026 12.0704C7.46033 12.6038 8.38526 12.8401 9.3276 12.8401V11.8401C8.54226 11.8401 7.83933 11.6423 7.3202 11.2629L6.73026 12.0704ZM10.5087 11.9223C10.2961 11.826 10.0588 11.7859 9.82146 11.8127L9.93386 12.8064C9.98646 12.8005 10.0427 12.809 10.0959 12.8331L10.5087 11.9223ZM11.7924 12.1142C11.8051 12.1377 11.8039 12.1497 11.8029 12.1561C11.8013 12.1663 11.7947 12.1859 11.7745 12.2087C11.7309 12.2579 11.6596 12.2848 11.5871 12.2682L11.3637 13.2429C11.8195 13.3474 12.2554 13.1735 12.5226 12.8723C12.7963 12.5637 12.915 12.0891 12.6726 11.6395L11.7924 12.1142ZM11.8289 10.7737C11.6611 11.001 11.6367 11.2919 11.6412 11.4794C11.6461 11.6813 11.689 11.9225 11.7924 12.1142L12.6726 11.6395C12.6739 11.6421 12.6653 11.6251 12.6561 11.5842C12.6477 11.5461 12.642 11.5009 12.6409 11.4553C12.6398 11.4089 12.6437 11.3725 12.6486 11.3495C12.6543 11.3224 12.6565 11.3363 12.6334 11.3676L11.8289 10.7737Z"
      fill="currentColor"
    />
  </svg>
);

export default Chat;
