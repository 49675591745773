import { useEffect, useMemo, useState } from 'react';
import { abbreviate } from '@chainflip/utils/string';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  type AvatarComponent,
  ConnectButton as RainbowButton,
  RainbowKitProvider,
  darkTheme,
  useConnectModal as useEvmConnectModal,
} from '@rainbow-me/rainbowkit';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useWalletModal as useSolanaWalletModal } from '@solana/wallet-adapter-react-ui';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import classNames from 'classnames';
import { type Address } from 'viem';
import { WagmiProvider, useAccount, useDisconnect, type Config } from 'wagmi';
import { useMobile } from '@/shared/hooks';
import { baseStyles, disabledStyles, hoverStyles } from './flip-ui-kit/Button';
import CopyButton from './molecules/CopyButton';
import IconButton from './molecules/IconButton';
import { EthereumTransparentLogo, SolanaTransparentLogo } from '../assets/chain-transparent-logos';
import { queryCacheConfig } from '../graphql/client';
import useTracking from '../hooks/useTracking';
import { PowerIcon } from '../icons/small';
import { SharedEvents, type SharedTrackEvents } from '../types';
import { getEthereumChain, noop } from '../utils';

const generateGradient = (address: Address) => {
  let hue1 = 0;
  try {
    hue1 = Number(BigInt(address) % 360n);
  } catch {
    // pass
  }
  const hue2 = (hue1 + 180) % 360;
  const saturation = 75;
  const lightness = 50;
  const color = `hsl(${hue1} ${saturation}% ${lightness}%)`;
  const color2 = `hsl(${hue2} ${saturation}% ${lightness}%)`;
  return `linear-gradient(135deg, ${color} 6.7%, ${color2} 95%)`;
};

export const ConnectAvatar: AvatarComponent = ({ ensImage, size, address }) => {
  if (ensImage) {
    return <img alt="Avatar" src={ensImage} width={size} height={size} className="rounded-full" />;
  }

  const background = generateGradient(address as Address);

  return <div className="rounded-full" style={{ background, width: size, height: size }} />;
};

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { connected?: boolean };

const Button = ({ className, children, connected = false, ...rest }: ButtonProps) => (
  <button
    {...rest}
    className={classNames(
      className,
      'h-10 min-w-[130px] rounded-md px-4 text-14 transition duration-300',
      'hover:cursor-pointer hover:disabled:cursor-default',
      'font-aeonikMedium font-medium outline-none',
      !connected && baseStyles['primary-standard'],
      !connected && hoverStyles['primary-standard'],
      !connected && disabledStyles['primary-standard'],
      connected && 'border border-cf-gray-4 bg-black text-cf-light-3',
      connected && 'hover:bg-cf-gray-2 hover:text-cf-white',
    )}
    type="button"
  >
    {children}
  </button>
);

const queryClient = new QueryClient({
  queryCache: new QueryCache(queryCacheConfig),
});

const Dropdown = ({
  children,
  items,
}: {
  children: React.ReactNode;
  items: {
    Icon: React.FC;
    label: string;
    onClick: () => void;
    disconnect?: () => void;
    textToCopy?: string;
  }[];
}) => (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>

    <DropdownMenu.Portal>
      <DropdownMenu.Content
        className="z-20 flex flex-col gap-y-2 rounded-md border border-cf-gray-4 bg-cf-gray-3 p-2.5 text-14 text-cf-light-3"
        sideOffset={10}
        align="end"
      >
        {items.map(({ label, Icon, onClick, textToCopy, disconnect }) => (
          <DropdownMenu.Item
            key={label}
            className={classNames(
              !disconnect && 'hover:bg-cf-gray-4 hover:text-white',
              'max-w-full whitespace-nowrap rounded-md outline-none transition',
            )}
            asChild
          >
            {disconnect ? (
              <div className="flex w-full items-center justify-between gap-x-4 p-1">
                <div className="flex items-center gap-x-2">
                  <Icon />
                  {label}
                </div>
                <div className="flex items-center gap-x-2">
                  {textToCopy && <CopyButton textToCopy={textToCopy} />}
                  <IconButton onClick={disconnect}>
                    <PowerIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <button
                type="button"
                onClick={onClick}
                className="flex w-full items-center gap-x-2 p-1"
              >
                <Icon />
                {label}
              </button>
            )}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
);

type SupportedWallet = 'evm' | 'sol';

function useSolana(noSolana: boolean) {
  const wallet = useSolanaWallet();
  if (noSolana) return { publicKey: null, disconnect: noop };
  return wallet;
}

export const ConnectButton = ({ disabled = [] }: { disabled?: SupportedWallet[] }) => {
  const track = useTracking<SharedTrackEvents>();
  const { connector, address } = useAccount();
  const isMobile = useMobile();
  const [lastWallet, setLastWallet] = useState<SupportedWallet>('evm');
  const { openConnectModal: openEvmConnectModal } = useEvmConnectModal();
  const { disconnect: disconnectEvm } = useDisconnect();

  const wallet = useSolana(disabled.includes('sol'));
  const { setVisible: setSolanaModalVisible } = useSolanaWalletModal();

  useEffect(() => {
    if (address) setLastWallet('evm');
  }, [address]);

  useEffect(() => {
    if (wallet.publicKey) setLastWallet('sol');
  }, [wallet.publicKey]);

  let button;

  if ((lastWallet === 'evm' && address) || !wallet.publicKey) {
    button = (
      <RainbowButton.Custom>
        {({ account, chain, mounted }) => {
          if (!mounted) return false; // do not render button on server

          let content;

          if (!account) {
            content = 'Connect Wallet';
          } else {
            track(SharedEvents.ConnectWallet, {
              props: {
                connectedWallet: account.address,
                walletProvider: connector?.name ?? '',
                path: window.location.pathname,
              },
            });
            content = (
              <div className="flex items-center justify-center space-x-2">
                {chain?.hasIcon && chain.iconUrl && (
                  <div
                    className="h-4 w-4 overflow-hidden rounded-full"
                    style={{ background: chain.iconBackground }}
                  >
                    <img
                      alt={chain.name ?? 'Chain icon'}
                      src={chain.iconUrl}
                      style={{ width: 16, height: 16 }}
                    />
                  </div>
                )}
                <span>{account.ensName ?? account.displayName}</span>
                {!isMobile && (
                  <ConnectAvatar size={16} address={account.address} ensImage={account.ensAvatar} />
                )}
              </div>
            );
          }

          return (
            <Button onClick={noop} connected={Boolean(account)}>
              {content}
            </Button>
          );
        }}
      </RainbowButton.Custom>
    );
  } else {
    button = (
      <Button onClick={noop} connected={Boolean(wallet.publicKey)}>
        {wallet.publicKey ? (
          <div className="flex items-center gap-x-2">
            <SolanaTransparentLogo />
            {abbreviate(wallet.publicKey.toBase58())}
          </div>
        ) : (
          'Connect Wallet'
        )}
      </Button>
    );
  }

  const items = useMemo(() => {
    const wallets = [];

    if (!disabled.includes('evm')) {
      wallets.push({
        label: address ? abbreviate(address) : 'Connect EVM',
        Icon: EthereumTransparentLogo,
        onClick: () => {
          openEvmConnectModal?.();
        },
        textToCopy: address,
        disconnect: address ? disconnectEvm : undefined,
      });
    }

    if (!disabled.includes('sol')) {
      wallets.push({
        label: wallet.publicKey ? abbreviate(wallet.publicKey.toBase58()) : 'Connect Solana',
        Icon: SolanaTransparentLogo,
        onClick: () => {
          setSolanaModalVisible(true);
        },
        textToCopy: wallet.publicKey?.toBase58(),
        disconnect: wallet.publicKey ? () => wallet.disconnect() : undefined,
      });
    }

    return wallets;
  }, [openEvmConnectModal, address, wallet.publicKey, wallet.disconnect, disconnectEvm, address]);

  return <Dropdown items={items}>{button}</Dropdown>;
};

export const Provider = ({ children, config }: { children: React.ReactNode; config: Config }) => (
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider
        theme={darkTheme({
          borderRadius: 'small',
          accentColor: '#46DA93',
          accentColorForeground: 'black',
        })}
        avatar={ConnectAvatar}
        initialChain={getEthereumChain().wagmiChain}
      >
        {children}
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
);
