import { useQuery } from '@tanstack/react-query';
import { useReadContract } from 'wagmi';
import { getChainflipNetwork } from '../utils';

export default function useAddressScreening(address: string | `0x${string}` | undefined) {
  const { data: blockedAddresses = new Set() } = useQuery({
    queryKey: ['blockedAddresses'],
    queryFn: async () => {
      const response = await fetch(
        new URL('/addresses/blocked', process.env.NEXT_PUBLIC_CHAINFLIP_BACKEND_URL).toString(),
      );

      if (!response.ok) return [] as string[];

      return (await response.json()) as string[];
    },
    select(addresses) {
      return new Set(addresses.map((addr) => addr.toLowerCase()));
    },
    enabled: getChainflipNetwork() === 'mainnet',
  });

  /**
   * https://go.chainalysis.com/chainalysis-oracle-docs.html
   */
  const { data: isSanctioned } = useReadContract({
    address: '0x40C57923924B5c5c5455c48D93317139ADDaC8fb',
    functionName: 'isSanctioned',
    args: [address as `0x${string}`],
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'addr',
            type: 'address',
          },
        ],
        name: 'isSanctioned',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    chainId: 1,
    query: {
      enabled:
        getChainflipNetwork() === 'mainnet' &&
        address !== undefined &&
        /^0x[a-f\d]{40}$/i.test(address),
    },
  });

  if (!address) return false;

  return isSanctioned || blockedAddresses.has(address.toLowerCase());
}
