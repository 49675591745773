const Scale = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.8644 7.16666H12.8023M3.19774 7.16666H6.13559M8 11.1667V3.83332M8 3.83332V3.16666M8 3.83332H3.16667M8 3.83332H12.8333M11.3333 3.83332L12.7159 6.90566C12.7933 7.07772 12.8333 7.26419 12.8333 7.45286V7.49999C12.8333 8.23639 12.2364 8.83332 11.5 8.83332H11.1667C10.4303 8.83332 9.83333 8.23639 9.83333 7.49999V7.45286C9.83333 7.26419 9.87333 7.07772 9.9508 6.90566L11.3333 3.83332ZM4.66667 3.83332L6.04923 6.90566C6.12664 7.07772 6.16667 7.26419 6.16667 7.45286V7.49999C6.16667 8.23639 5.56971 8.83332 4.83333 8.83332H4.5C3.76362 8.83332 3.16667 8.23639 3.16667 7.49999V7.45286C3.16667 7.26419 3.20669 7.07772 3.28411 6.90566L4.66667 3.83332ZM10.1667 12.8333V12.5C10.1667 11.7636 9.56973 11.1667 8.83333 11.1667H7.16667C6.43029 11.1667 5.83333 11.7636 5.83333 12.5V12.8333H10.1667Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Scale;
