import React from 'react';
import { assetConstants, type ChainflipAsset } from '@chainflip/utils/chainflip';
import { formatUsdValue } from '@chainflip/utils/number';
import classNames from 'classnames';
import { chainflipAssetMap } from '@/shared/utils';
import { useMobile } from '../hooks';
import { assetStyles, chainStyles } from '../utils/chainflip';

const dimensions = {
  xsmall: {
    containerSizeClasses: 'w-[16px] h-[16px]',
    token: {
      width: 16,
      height: 16,
    },
    chain: {
      width: 9,
      height: 9,
      position: 'left-[9px] top-[9px]',
    },
  },
  small: {
    containerSizeClasses: 'w-[19px] h-[19px]',
    token: {
      width: 19,
      height: 19,
    },
    chain: {
      width: 9,
      height: 9,
      position: 'left-[12px] top-[13px]',
    },
  },
  medium: {
    containerSizeClasses: 'w-[25px] h-[25px]',
    token: {
      width: 25,
      height: 25,
    },
    chain: {
      width: 12,
      height: 12,
      position: 'left-[16px] top-[16px]',
    },
  },
  large: {
    containerSizeClasses: 'w-[32px] h-[32px]',
    token: {
      width: 32,
      height: 32,
    },
    chain: {
      width: 16,
      height: 16,
      position: 'left-[21px] top-[21px]',
    },
  },
} as const satisfies Record<
  TokenWithChainSize,
  {
    containerSizeClasses: string;
    token: {
      width: number;
      height: number;
    };
    chain: {
      width: number;
      height: number;
      position: string;
    };
  }
>;

export type TokenWithChainSize = 'xsmall' | 'small' | 'medium' | 'large';
const DEFAULT_SIZE = 'small';

export const TokenWithChain = ({
  chainLogo,
  tokenLogo,
  displayChainLogo = true,
  size = DEFAULT_SIZE,
}: {
  chainLogo: ((props?: React.SVGProps<SVGSVGElement>) => JSX.Element) | JSX.Element;
  tokenLogo: ((props?: React.SVGProps<SVGSVGElement>) => JSX.Element) | JSX.Element;
  displayChainLogo?: boolean;
  size?: TokenWithChainSize;
}): JSX.Element => (
  <div className={classNames('relative', dimensions[size].containerSizeClasses)}>
    {typeof tokenLogo === 'function' ? tokenLogo(dimensions[size].token) : tokenLogo}
    {displayChainLogo && (
      <div
        style={{ filter: 'drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.15))' }}
        className={classNames('absolute', dimensions[size].chain.position)}
      >
        {typeof chainLogo === 'function' ? chainLogo(dimensions[size].chain) : chainLogo}
      </div>
    )}
  </div>
);

export const TokenWithChainByAsset = ({
  asset,
  size = DEFAULT_SIZE,
  displayChainLogo = true,
}: {
  asset: ChainflipAsset;
  size?: TokenWithChainSize;
  displayChainLogo?: boolean;
}) => (
  <TokenWithChain
    chainLogo={chainStyles[assetConstants[asset].chain].logo}
    tokenLogo={assetStyles[asset].logo}
    size={size}
    displayChainLogo={displayChainLogo}
  />
);

export const TokenAmountWithChainByAsset = ({
  asset,
  amount,
  usdAmount,
  displayChainLogo = true,
  amountClass = 'text-12 lg:text-16 text-white',
  size = 'large',
  compact = false,
  disabled = false,
}: {
  asset: ChainflipAsset;
  amount?: string;
  usdAmount?: string | number;
  displayChainLogo?: boolean;
  amountClass?: string;
  size?: TokenWithChainSize;
  compact?: boolean;
  disabled?: boolean;
}) => {
  const isMobile = useMobile();
  const assetSize = isMobile ? 'medium' : size;

  return (
    <div
      className={classNames(
        compact ? 'gap-x-2' : 'gap-x-3 lg:gap-x-4',
        disabled && 'opacity-30',
        'flex items-center',
      )}
    >
      <TokenWithChainByAsset asset={asset} size={assetSize} displayChainLogo={displayChainLogo} />
      {amount && (
        <div className="flex flex-col">
          <span className={classNames('font-normal', amountClass)}>
            {amount} {!compact && chainflipAssetMap[asset].symbol}
          </span>

          {usdAmount && (
            <span className="text-[11px] font-normal text-cf-light-2 lg:text-12">
              {formatUsdValue(usdAmount)}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
