import { isNotNullish } from '@chainflip/utils/guard';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { getAllBoostPoolBalancesForAccount } from '@/shared/queries/boost';

export type BoostBalancesProps = {
  account?: string;
  enabled?: boolean;
};
export default function useBoostBalances({ account, enabled = true }: BoostBalancesProps) {
  return useGqlQuery(getAllBoostPoolBalancesForAccount, {
    variables: { accountId: account as string },
    context: { clientName: 'statechainCache' },
    refetchInterval: 5_000,
    enabled: isNotNullish(account) && enabled,
  });
}
