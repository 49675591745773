import { format } from 'date-fns';
import { FLIP_SYMBOL } from '@/shared/utils/env';
import TokenAmount from './TokenAmount';

// format to number{s} or number{m} or number{h}
export const formatToApproxTime = (durationSeconds: number) => {
  if (durationSeconds < 60) {
    return `${durationSeconds.toFixed()}s`;
  }

  if (durationSeconds < 3600) {
    return `${Math.floor(durationSeconds / 60)}m`;
  }

  return `${Math.floor(durationSeconds / 3600)}h`;
};

export const pad = (number: number) => String(number).padStart(2, '0');

export const addToGoogleCalendar = (amount: bigint, startTimestamp: Date, endTimestamp: Date) => {
  const flipAmount = new TokenAmount(amount).toFixed();
  const callbackUrl = process.env.NEXT_PUBLIC_REDEMPTIONS_CALENDAR_CALLBACK_URL;

  const formatDateForCalendar = (date: Date) => format(date, "yyyyLLdd'T'HHmmss");
  const start = formatDateForCalendar(startTimestamp);
  const end = formatDateForCalendar(endTimestamp);

  const GoogleCalenderUrl = 'https://calendar.google.com/calendar/render';

  const queryParams = {
    action: 'TEMPLATE',
    dates: `${start}/${end}`,
    text: `Redeem ${flipAmount} ${FLIP_SYMBOL}`,
    details: `Finalise redemption of ${flipAmount} ${FLIP_SYMBOL} from <a href="${callbackUrl}">${callbackUrl}</a>`,
  };

  const url = `${GoogleCalenderUrl}?${new URLSearchParams(queryParams).toString()}`;

  window.open(url, '_blank');
};

// eg: "1h 2m", "expired", "less than a minute"
export const formatTimeUntilExpiry = (duration: number, expiredText = 'Expired') => {
  if (duration <= 0) {
    return expiredText;
  }

  if (duration <= 60) {
    return 'less than a minute';
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);

  const hoursRepresentation = hours > 0 ? `${hours}h` : '';
  const minutesRepresentation = minutes > 0 ? `${minutes}m` : '';

  return `${hoursRepresentation} ${minutesRepresentation}`;
};

export const getDateTruncatedToHour = (date: Date) => {
  const year = String(date.getUTCFullYear());
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getMonth() returns a zero-based value for the month (zero is the first month)
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = '00';
  const seconds = '00';

  return new Date(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
};

export const getRemainingRetryTime = (fokDuration: number, scheduledAt: number): number =>
  fokDuration * 6 - (Date.now() - scheduledAt) / 1000;
