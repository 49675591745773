export const enum SharedEvents {
  ConnectWallet = 'connect_wallet',
  AddBoostLiquidity = 'add_boost_liquidity',
}
export type SharedTrackEvents = {
  [SharedEvents.ConnectWallet]: {
    connectedWallet: string;
    walletProvider: string;
    path: string;
  };
  [SharedEvents.AddBoostLiquidity]: {
    path: string;
    position: 'my_boost_positions' | 'boost_pools' | 'boost_pool_overview';
    scAccount: string;
    feeTierBps: number;
  };
};
