const TimeSpeed = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M6.01951 12.344C6.65423 12.6866 7.36702 12.8585 8.08813 12.843C8.80925 12.8275 9.51398 12.6251 10.1334 12.2555C10.7528 11.886 11.2657 11.3619 11.6218 10.7347C11.978 10.1075 12.1652 9.39859 12.1652 8.67731C12.1652 7.95603 11.978 7.2471 11.6218 6.61989C11.2657 5.99267 10.7528 5.46865 10.1334 5.0991C9.51398 4.72954 8.80925 4.52711 8.08813 4.5116C7.36702 4.4961 6.65423 4.66805 6.01951 5.01064M3.16684 6.67731H5.50017M3.16684 10.6773H5.50017M3.16684 8.67731H4.16684M11.0002 5.67731L11.5002 5.17731M8.00017 4.34397V3.17731M8.00017 3.17731H6.50017M8.00017 3.17731H9.50017M9.50017 7.17731L7.83351 8.84397"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TimeSpeed;
