import { type SwapStatusResponseV2 } from '@chainflip/sdk/swap';
import { TokenAmount } from '@/shared/utils';
import { type StatusResponse } from '../integrations';
import {
  cfIsBoostedChannel,
  cfIsBoostSkipped,
  cfIsSwapBoosted,
  cfIsSwappingFinished,
} from '../integrations/chainflip';

export const getSwapObject = (status: StatusResponse) =>
  status.integrationData && 'swap' in status.integrationData && status.integrationData.swap
    ? status.integrationData.swap
    : undefined;

export const getDepositedAmount = (status: StatusResponse) =>
  status.integration === 'chainflip' &&
  status.integrationData &&
  'deposit' in status.integrationData &&
  status.integrationData.deposit
    ? TokenAmount.fromAsset(
        status.integrationData.deposit.amount,
        status.route.srcToken.chainflipId,
      )
    : undefined;

export const getSwapEgress = (status: StatusResponse) =>
  status.integrationData &&
  'swapEgress' in status.integrationData &&
  status.integrationData.swapEgress
    ? status.integrationData.swapEgress
    : undefined;

export const getRefundEgress = (status: StatusResponse) =>
  status.integrationData &&
  'refundEgress' in status.integrationData &&
  status.integrationData.refundEgress
    ? status.integrationData.refundEgress
    : undefined;

export const getSwapFailure = (status: StatusResponse) =>
  status.integrationData &&
  'swapEgress' in status.integrationData &&
  status.integrationData.swapEgress &&
  'failure' in status.integrationData.swapEgress
    ? status.integrationData.swapEgress.failure
    : undefined;

export const getRefundFailure = (status: StatusResponse) =>
  status.integrationData &&
  'refundEgress' in status.integrationData &&
  status.integrationData.refundEgress &&
  'failure' in status.integrationData.refundEgress
    ? status.integrationData.refundEgress.failure
    : undefined;

export const isDepositFinished = (status: StatusResponse) => {
  if (status.integration === 'chainflip' && status.integrationData?.state !== 'WAITING') {
    return Boolean(status.integrationData?.deposit.witnessedAt);
  }
  return false;
};

export const isBoostedChannel = (status: StatusResponse) =>
  status.integration === 'chainflip' && cfIsBoostedChannel(status.integrationData);

export const isBoostSkipped = (status: StatusResponse) =>
  status.integration === 'chainflip' && cfIsBoostSkipped(status.integrationData);

export const isSwapBoosted = (status: StatusResponse) =>
  status.integration === 'chainflip' && cfIsSwapBoosted(status.integrationData);

export const isBoostPending = (status: StatusResponse) =>
  isBoostedChannel(status) && !isBoostSkipped(status) && !isSwapBoosted(status);

export const isVaultSwap = (status: StatusResponse) =>
  status.integration === 'chainflip' && !status.integrationData?.depositChannel;

export const isSwappingFinished = (status: StatusResponse) =>
  status.integration === 'chainflip' && cfIsSwappingFinished(status.integrationData);

export const getSwapScheduledAt = (status: StatusResponse) => {
  if (status.integrationData && 'swap' in status.integrationData && status.integrationData.swap)
    if (status.integrationData.swap.dca?.currentChunk) {
      return status.integrationData.swap.dca.currentChunk.scheduledAt;
    } else if (status.integrationData.swap.regular) {
      return status.integrationData.swap.regular.scheduledAt;
    }
  return undefined;
};

export const getSwapFees = (status: StatusResponse) =>
  status.integrationData && 'fees' in status.integrationData
    ? status.integrationData.fees
    : undefined;

export const getEgressAmount = (sdkStatus: SwapStatusResponseV2 | undefined) =>
  sdkStatus && 'swapEgress' in sdkStatus && sdkStatus.swapEgress
    ? sdkStatus.swapEgress.amount
    : undefined;

export const getSwapDuration = (sdkStatus: SwapStatusResponseV2 | undefined) =>
  sdkStatus &&
  'swapEgress' in sdkStatus &&
  sdkStatus.swapEgress &&
  sdkStatus.swapEgress.scheduledAt &&
  sdkStatus.depositChannel
    ? sdkStatus.swapEgress.scheduledAt - sdkStatus.depositChannel.createdAt
    : undefined;

export const getSwapId = (status: StatusResponse) => {
  if (
    status.integration === 'chainflip' &&
    status.integrationData &&
    'swapId' in status.integrationData
  ) {
    return status.integrationData.swapId;
  }

  return status.id;
};
