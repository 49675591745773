const BlockOutside = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 11.75V17.25C5 17.7804 5.21071 18.2891 5.58579 18.6642C5.96086 19.0393 6.46957 19.25 7 19.25H17.5C18.0304 19.25 18.5391 19.0393 18.9142 18.6642C19.2893 18.2891 19.5 17.7804 19.5 17.25V11.75M12.25 9.25V4.75M12.25 4.75L10 7.25M12.25 4.75L14.5 7.25M14.5 15.75H10C9.73478 15.75 9.48043 15.6446 9.29289 15.4571C9.10536 15.2696 9 15.0152 9 14.75V13.25C9 12.9848 9.10536 12.7304 9.29289 12.5429C9.48043 12.3554 9.73478 12.25 10 12.25H14.5C14.7652 12.25 15.0196 12.3554 15.2071 12.5429C15.3946 12.7304 15.5 12.9848 15.5 13.25V14.75C15.5 15.0152 15.3946 15.2696 15.2071 15.4571C15.0196 15.6446 14.7652 15.75 14.5 15.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BlockOutside;
