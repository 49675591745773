const ChartCheck = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M6.55172 12.2414H6.03448C5.46316 12.2414 5 12.7045 5 13.2759V18.9655C5 19.5369 5.46316 20 6.03448 20H6.55172C7.12305 20 7.58621 19.5369 7.58621 18.9655V13.2759C7.58621 12.7045 7.12305 12.2414 6.55172 12.2414Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1552 5H16.6379C16.0666 5 15.6035 5.46316 15.6035 6.03448V18.9655C15.6035 19.5369 16.0666 20 16.6379 20H17.1552C17.7265 20 18.1897 19.5369 18.1897 18.9655V6.03448C18.1897 5.46316 17.7265 5 17.1552 5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9828 9.13792H11.4655C10.8942 9.13792 10.431 9.60108 10.431 10.1724V18.9655C10.431 19.5369 10.8942 20 11.4655 20H11.9828C12.5541 20 13.0172 19.5369 13.0172 18.9655V10.1724C13.0172 9.60108 12.5541 9.13792 11.9828 9.13792Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 7.32759L6.55172 8.87931C7.32759 6.55172 9.65517 5.25862 9.65517 5.25862"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChartCheck;
