import { type ChainflipChain } from '@chainflip/utils/chainflip';
import useRpcRequest from './useRpcRequest';

export const useRpcChainflipBlockConfirmations = () =>
  useRpcRequest({
    method: 'cf_environment',
    args: [],
    select: (result) => {
      const confirmationsMap = result.ingress_egress.witness_safety_margins;
      return Object.keys(confirmationsMap).reduce(
        (acc, chain) => {
          acc[chain as ChainflipChain] = confirmationsMap[chain as ChainflipChain]
            ? Number(confirmationsMap[chain as ChainflipChain]) + 1
            : undefined;
          return acc;
        },
        {
          Bitcoin: 3,
        } as Record<ChainflipChain, number | undefined>,
      );
    },
  });
