const Alert = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 9.5C7.72387 9.5 7.5 9.72387 7.5 10C7.5 10.2761 7.72387 10.5 8 10.5V9.5ZM8.00667 10.5C8.2828 10.5 8.50667 10.2761 8.50667 10C8.50667 9.72387 8.2828 9.5 8.00667 9.5V10.5ZM8 10.5H8.00667V9.5H8V10.5Z"
      fill="currentColor"
    />
    <path
      d="M6.93554 3.60756L7.28914 3.96112L6.93554 3.60756ZM3.60758 6.93552L3.25403 6.582L3.60758 6.93552ZM3.60758 9.06446L3.96113 8.71086L3.60758 9.06446ZM6.93554 12.3924L7.28914 12.0389L6.93554 12.3924ZM9.06447 12.3924L9.41801 12.746L9.06447 12.3924ZM12.3924 6.93552L12.746 6.582L12.3924 6.93552ZM9.06447 3.60756L9.41801 3.25401L9.06447 3.60756ZM6.58201 3.25401L3.25403 6.582L3.96113 7.28912L7.28914 3.96112L6.58201 3.25401ZM3.25403 9.41799L6.58201 12.746L7.28914 12.0389L3.96113 8.71086L3.25403 9.41799ZM9.41801 12.746L12.746 9.41799L12.0389 8.71086L8.71087 12.0389L9.41801 12.746ZM12.746 6.582L9.41801 3.25401L8.71087 3.96112L12.0389 7.28912L12.746 6.582ZM12.746 9.41799C13.5291 8.63486 13.5291 7.36512 12.746 6.582L12.0389 7.28912C12.4315 7.68172 12.4315 8.31826 12.0389 8.71086L12.746 9.41799ZM6.58201 12.746C7.36514 13.5291 8.63487 13.5291 9.41801 12.746L8.71087 12.0389C8.31827 12.4315 7.68174 12.4315 7.28914 12.0389L6.58201 12.746ZM3.25403 6.582C2.47089 7.36512 2.47089 8.63486 3.25403 9.41799L3.96113 8.71086C3.56852 8.31826 3.56852 7.68172 3.96113 7.28912L3.25403 6.582ZM7.28914 3.96112C7.68174 3.5685 8.31827 3.5685 8.71087 3.96112L9.41801 3.25401C8.63487 2.47087 7.36514 2.47087 6.58201 3.25401L7.28914 3.96112Z"
      fill="currentColor"
    />
    <path
      d="M8 5.83334V8.16668"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Alert;
