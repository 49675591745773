const Broker = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="8.05139"
      y1="8.73163"
      x2="8.05139"
      y2="11.7316"
      stroke="currentColor"
      strokeWidth="1.125"
    />
    <line
      x1="8.05139"
      y1="4.23163"
      x2="8.05139"
      y2="7.23163"
      stroke="currentColor"
      strokeWidth="1.125"
    />
    <line
      x1="4.48889"
      y1="8.16913"
      x2="6.90497"
      y2="8.16913"
      stroke="currentColor"
      strokeWidth="1.125"
    />
    <line
      x1="8.98889"
      y1="8.16913"
      x2="11.9889"
      y2="8.16913"
      stroke="currentColor"
      strokeWidth="1.125"
    />
    <path
      d="M8.9375 3.5C8.9375 3.74864 8.83873 3.9871 8.66291 4.16291C8.4871 4.33873 8.24864 4.4375 8 4.4375C7.75136 4.4375 7.5129 4.33873 7.33709 4.16291C7.16127 3.9871 7.0625 3.74864 7.0625 3.5C7.0625 3.25136 7.16127 3.0129 7.33709 2.83709C7.5129 2.66127 7.75136 2.5625 8 2.5625C8.24864 2.5625 8.4871 2.66127 8.66291 2.83709C8.83873 3.0129 8.9375 3.25136 8.9375 3.5ZM13.4375 8C13.4375 8.24864 13.3387 8.4871 13.1629 8.66291C12.9871 8.83873 12.7486 8.9375 12.5 8.9375C12.2514 8.9375 12.0129 8.83873 11.8371 8.66291C11.6613 8.4871 11.5625 8.24864 11.5625 8C11.5625 7.75136 11.6613 7.5129 11.8371 7.33709C12.0129 7.16127 12.2514 7.0625 12.5 7.0625C12.7486 7.0625 12.9871 7.16127 13.1629 7.33709C13.3387 7.5129 13.4375 7.75136 13.4375 8ZM4.4375 8C4.4375 8.24864 4.33873 8.4871 4.16291 8.66291C3.9871 8.83873 3.74864 8.9375 3.5 8.9375C3.25136 8.9375 3.0129 8.83873 2.83709 8.66291C2.66127 8.4871 2.5625 8.24864 2.5625 8C2.5625 7.75136 2.66127 7.5129 2.83709 7.33709C3.0129 7.16127 3.25136 7.0625 3.5 7.0625C3.74864 7.0625 3.9871 7.16127 4.16291 7.33709C4.33873 7.5129 4.4375 7.75136 4.4375 8ZM8.9375 12.5C8.9375 12.7486 8.83873 12.9871 8.66291 13.1629C8.4871 13.3387 8.24864 13.4375 8 13.4375C7.75136 13.4375 7.5129 13.3387 7.33709 13.1629C7.16127 12.9871 7.0625 12.7486 7.0625 12.5C7.0625 12.2514 7.16127 12.0129 7.33709 11.8371C7.5129 11.6613 7.75136 11.5625 8 11.5625C8.24864 11.5625 8.4871 11.6613 8.66291 11.8371C8.83873 12.0129 8.9375 12.2514 8.9375 12.5Z"
      stroke="currentColor"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.93203 8.93209C9.17924 8.68489 9.31812 8.3496 9.31812 8C9.31812 7.6504 9.17924 7.31511 8.93203 7.06791C8.68482 6.8207 8.34954 6.68182 7.99994 6.68182C7.65034 6.68182 7.31505 6.8207 7.06785 7.06791C6.82064 7.31511 6.68176 7.6504 6.68176 8C6.68176 8.3496 6.82064 8.68489 7.06785 8.93209C7.31505 9.1793 7.65034 9.31818 7.99994 9.31818C8.34954 9.31818 8.68482 9.1793 8.93203 8.93209Z"
      stroke="currentColor"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Broker;
