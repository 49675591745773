import { isTruthy } from '@chainflip/utils/guard';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  bitgetWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  rainbowWallet,
  subWallet,
  talismanWallet,
  walletConnectWallet,
  xdefiWallet,
  phantomWallet,
  trustWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { fallback, http, type Chain as WagmiChain } from 'viem';
import { arbitrum, arbitrumSepolia, mainnet, sepolia } from 'wagmi/chains';
import { chains, backspinArbitrum, backspinEthereum } from '@/shared/assets/chains';
import { isTestnet } from '@/shared/featureFlags';
import { getEthereumChain } from '@/shared/utils/env';

const projectId = '9d94e593343f9901d073e88eb0c28c18'; // managed by 0xa56A6be23b6Cf39D9448FF6e897C29c41c8fbDFF

const getConfig = (wagmiChains: [WagmiChain, ...WagmiChain[]]) =>
  getDefaultConfig({
    appName: 'Chainflip App',
    projectId,
    chains: wagmiChains,
    ssr: true,
    transports: {
      [mainnet.id]: fallback([
        http(`https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_API_KEY}`),
        http(),
      ]),
      [sepolia.id]: fallback([
        http(`https://sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_API_KEY}`),
        http(),
      ]),
      [arbitrum.id]: fallback([
        http(`https://arbitrum-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_API_KEY}`),
        http(),
      ]),
      [arbitrumSepolia.id]: fallback([
        http(`https://arbitrum-sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_API_KEY}`),
        http(),
      ]),
      [backspinEthereum.wagmiChain.id]: fallback([
        http(backspinEthereum.wagmiChain.rpcUrls.default.http as unknown as string),
      ]),
      [backspinArbitrum.wagmiChain.id]: fallback([
        http(backspinArbitrum.wagmiChain.rpcUrls.default.http as unknown as string),
      ]),
    },
    wallets: [
      {
        groupName: 'Recommended',
        wallets: [
          injectedWallet,
          metaMaskWallet,
          rabbyWallet,
          rainbowWallet,
          walletConnectWallet,
          !isTestnet ? xdefiWallet : undefined,
          okxWallet,
          coinbaseWallet,
          subWallet,
          bitgetWallet,
          talismanWallet,
          phantomWallet,
          trustWallet,
        ].filter(isTruthy),
      },
    ],
  });

export const defaultRainbowConfig = getConfig([getEthereumChain().wagmiChain]);

export const swapRainbowConfig = getConfig(
  chains.map((c) => c.wagmiChain).filter(isTruthy) as [WagmiChain, ...WagmiChain[]],
);
