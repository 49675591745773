const Twitter = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.20666 12.1666C9.85459 12.1666 11.8496 8.96018 11.8496 6.17994C11.8496 6.02453 11.9597 5.88675 12.1013 5.82263C12.5869 5.60273 13.2166 4.9996 12.5663 3.9855C12.1354 4.21715 11.7813 4.34373 11.3069 4.4963C10.556 3.64927 9.29966 3.60844 8.50092 4.40505C7.98566 4.91877 7.76732 5.68455 7.92712 6.41534C6.33233 6.33077 4.46524 5.15708 3.45832 3.84258C2.93208 4.80419 3.2007 6.03412 4.07214 6.65199C3.7564 6.64199 3.44772 6.55158 3.17204 6.38868V6.41534C3.17204 7.41698 3.83768 8.27938 4.76292 8.47771C4.47114 8.56231 4.16482 8.57478 3.86753 8.51398C4.12751 9.37098 5.25315 10.3326 6.1018 10.3496C5.39924 10.935 4.53173 11.253 3.6387 11.2521C3.48083 11.2517 3.32335 11.2417 3.16666 11.2217C4.07383 11.8392 5.12905 12.1666 6.20666 12.165V12.1666Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Twitter;
