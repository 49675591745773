import * as Sentry from '@sentry/react';
import { type Query } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';

const ONE_HOUR = 1000 * 60 * 60;

export const processorClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_PROCESSOR_GRAPHQL_API as string,
);

export const cacheClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_STATECHAIN_CACHE_GRAPHQL_API as string,
);

export const lpProcessorClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_LP_PROCESSOR_GRAPHQL_API as string,
);

export const queryCacheConfig = {
  onError: (error: Error, query: Query<unknown, unknown, unknown>) => {
    // check to see if graphql schema has changed and refresh
    if (error.message.includes('does not exist')) {
      const lastRefresh = localStorage.getItem('schema-ood-refresh');

      if (!lastRefresh || Date.now() - parseInt(lastRefresh as string, 10) > ONE_HOUR) {
        localStorage.setItem('schema-ood-refresh', Date.now().toString());
        window.location.reload();

        return;
      }
    }

    Sentry.captureException(error, {
      extra: { queryKey: query.queryKey },
    });
  },
};
