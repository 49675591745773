import { useEffect, useRef } from 'react';
import { type BaseChainflipAsset } from '@chainflip/utils/chainflip';
import {
  type ChartingLibraryFeatureset,
  type ChartingLibraryWidgetOptions,
  type ResolutionString,
  widget as Widget,
  type WidgetOverrides,
} from '@tradingview/chart';
import { useTradingViewDatafeed } from '../hooks/useTradingViewDatafeed';

const DEFAULT_SETTINGS = {
  'paneProperties.background': '#111111',
  'paneProperties.backgroundType': 'solid',
  'paneProperties.crossHairProperties.style': 1,
  'paneProperties.legendProperties.showBarChange': false,
  'mainSeriesProperties.style': 1,
};
const format = (date: Date) =>
  date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });

export const TVChartContainer = ({
  asset,
  timeframe = '2M' as ResolutionString,
  interval = '1D' as ResolutionString,
  disabledFeatures = [],
  settings = {},
}: {
  asset: BaseChainflipAsset;
  timeframe?: ResolutionString;
  interval?: ResolutionString;
  disabledFeatures?: ChartingLibraryFeatureset[];
  settings?: Partial<WidgetOverrides>;
}) => {
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { datafeed } = useTradingViewDatafeed();

  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: `${asset}-Usdc`,
      datafeed,
      interval,
      header_widget_buttons_mode: 'adaptive',
      container: chartContainerRef.current,
      library_path: '/static/charting_library/',
      custom_css_url: '/static/tradingview.css',
      locale: 'en',
      client_id: 'tradingview.com',
      fullscreen: false,
      autosize: true,
      timeframe,
      favorites: {
        intervals: ['1H', '1D', '1W'] as ResolutionString[],
        chartTypes: ['Candles'],
      },
      enabled_features: [
        'remove_library_container_border',
        'hide_last_na_study_output',
        'dont_show_boolean_study_arguments',
        'hide_left_toolbar_by_default',
      ],
      debug: process.env.NODE_ENV === 'development',
      disabled_features: [
        'header_symbol_search',
        'header_compare',
        'symbol_search_hot_key',
        'symbol_info',
        'go_to_date',
        'timeframes_toolbar',
        ...disabledFeatures,
      ],
      theme: 'dark',
      overrides: {
        ...DEFAULT_SETTINGS,
        ...settings,
      },
      settings_overrides: {
        ...DEFAULT_SETTINGS,
        ...settings,
      },
      custom_font_family: "'Aeonik-Regular', 'Helvetica', 'Arial', sans-serif",
      toolbar_bg: '#111111',
      loading_screen: {
        backgroundColor: 'rgba(17, 17, 17, 1)',
      },
      custom_formatters: {
        timeFormatter: {
          format,
          formatLocal: format,
          parse: (value: string) => format(new Date(value)),
        },
      },
    };

    const tvWidget = new Widget(widgetOptions);

    return () => {
      tvWidget?.remove();
    };
  }, [asset, timeframe, interval]);

  return <div ref={chartContainerRef} className="h-full" />;
};
