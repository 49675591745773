const Coins = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.83333 6.16663V4.33329M12.8333 4.33329V6.99996C12.8333 7.28823 12.5197 7.55203 12 7.75563M12.8333 4.33329C12.8333 4.97763 11.2663 5.49996 9.33333 5.49996C7.40033 5.49996 5.83333 4.97763 5.83333 4.33329M12.8333 4.33329C12.8333 3.68896 11.2663 3.16663 9.33333 3.16663C7.40033 3.16663 5.83333 3.68896 5.83333 4.33329"
      stroke="currentColor"
      strokeWidth="1.00051"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1667 9.00004V11.6667C10.1667 12.311 8.59967 12.8334 6.66667 12.8334C4.73367 12.8334 3.16667 12.311 3.16667 11.6667V9.00004M10.1667 9.00004C10.1667 9.64437 8.59967 10.1667 6.66667 10.1667C4.73367 10.1667 3.16667 9.64437 3.16667 9.00004M10.1667 9.00004C10.1667 8.35571 8.59967 7.83337 6.66667 7.83337C4.73367 7.83337 3.16667 8.35571 3.16667 9.00004"
      stroke="currentColor"
      strokeWidth="1.00051"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Coins;
