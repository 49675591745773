import { type ChainflipAsset } from '@chainflip/utils/chainflip';
import { isNotNullish } from '@chainflip/utils/guard';

export type BoostPool = { asset: ChainflipAsset; feeTierPips: number };

export type BoostPoolId = `${ChainflipAsset}-${number}`;
export const getBoostPoolId = (pool: BoostPool) =>
  `${pool.asset}-${pool.feeTierPips}` as BoostPoolId;

export const boostPoolColors = {
  'Btc-30': '#46DA93', // cf-green-1
  'Btc-10': '#C9A8FF', // cf-blue-4
  'Btc-5': '#F4BD8A', // ???
} as Record<BoostPoolId, string>;

export const defaultPools = [
  { asset: 'Btc', feeTierPips: 30 },
  { asset: 'Btc', feeTierPips: 10 },
  { asset: 'Btc', feeTierPips: 5 },
] as BoostPool[];

export const apyToText = (apy?: number) => {
  if (isNotNullish(apy)) {
    const decimals = apy < 0.01 && apy > 0 ? 4 : 2;
    return `${apy.toFixed(decimals)}%`;
  }
  return '-';
};
