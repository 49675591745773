import React, { type ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ChainflipLogo } from '@/shared/assets/chainflip-logo';
import { Link } from '@/shared/components';
import { ChainflipNetworkBadge } from '@/shared/components/atoms/ChainflipNetworkBadge';
import { CloseIcon, HamburgerIcon } from '@/shared/icons/large';
import { useMobile } from '../hooks';

const Navbar = ({
  desktopContent,
  mobileContent,
  logoHref,
  isFixed = false,
  className,
}: {
  desktopContent?: ReactNode;
  mobileContent?: ReactNode;
  logoHref: string;
  isFixed?: boolean;
  className?: string;
}): JSX.Element => {
  const [openNavModal, setOpenNavModal] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    if (openNavModal) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [openNavModal]);

  return (
    <div
      className={classNames(
        'flex h-[74px] w-full flex-col items-center justify-center py-4',
        isFixed ? 'fixed z-[1000] bg-[rgba(13,13,13,0.70)] backdrop-blur-[20px]' : 'z-10',
        className,
      )}
      style={{ top: 'var(--banner-height)' }}
    >
      {!openNavModal && (
        <div className="flex w-full items-center justify-between gap-x-3 px-4 2xl:container md:px-8">
          <div className="flex items-center gap-x-[25px]">
            <Link href={logoHref}>
              <ChainflipLogo />
            </Link>
            <ChainflipNetworkBadge isActive={!isMobile} />
          </div>
          <div className="hidden grow items-center justify-end gap-x-12 text-14 lg:flex">
            {desktopContent}
          </div>
          <div onClick={() => setOpenNavModal(true)} className="cursor-pointer lg:hidden">
            <HamburgerIcon />
          </div>
        </div>
      )}
      {openNavModal && (
        <>
          <div className="flex w-full items-center justify-between px-4">
            <div className="flex items-center gap-x-[25px]">
              <Link href={logoHref}>
                <ChainflipLogo />
              </Link>
            </div>
            <button type="button" className="ml-auto" onClick={() => setOpenNavModal(false)}>
              <CloseIcon className="cursor-pointer" />
            </button>
          </div>
          <div className="fixed top-[74px] z-20 flex h-[calc(100vh-74px)] w-full flex-col overflow-y-scroll bg-black px-4">
            <div className="flex flex-col [&>*]:border-b [&>*]:border-b-cf-gray-3 [&>*]:py-5">
              {mobileContent}
              <ChainflipNetworkBadge />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
