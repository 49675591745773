import { type ChainId } from '@/shared/assets/chains/index';
import { type Token } from '@/shared/assets/tokens/index';
import { tokenLogo } from '@/shared/assets/tokens/logo';
import { chainLogo } from '../../assets/chains/logo';
import * as tokenLogos from '../../assets/token-logos';
import { formatIpfsUrl } from '../../utils/strings';
import { TokenWithChain, type TokenWithChainSize } from '../TokenWithChain';

export type LogoToken = Pick<Token, 'logo' | 'symbol'> & {
  chain: { id: ChainId };
};

const TokenLogo = ({
  token,
  height = 34,
  width = 34,
}: {
  token: Pick<Token, 'symbol' | 'logo'>;
  height?: number;
  width?: number;
}) => {
  if (!tokenLogo[token.symbol]) {
    return (
      <div style={{ height: `${height}px`, width: `${width}px` }}>
        {typeof token.logo === 'string' && token.logo.length > 1 ? (
          <img className="rounded-[100%]" src={formatIpfsUrl(token.logo)} alt="" />
        ) : (
          <tokenLogos.NotFound width={width} height={height} />
        )}
      </div>
    );
  }
  return (
    <div style={{ height: `${height}px`, width: `${width}px` }}>
      {tokenLogo[token.symbol]({ width, height })}
    </div>
  );
};

// TODO: Merge this component with TokenWithChain component
const logoSizes = {
  xsmall: 16,
  small: 19,
  medium: 24,
  large: 32,
} as const satisfies Record<TokenWithChainSize, number>;

function ChainTokenLogo({
  token,
  size = 'large',
  displayChainLogo = true,
}: {
  token: LogoToken;
  size?: TokenWithChainSize;
  displayChainLogo?: boolean;
}): JSX.Element {
  const logoSize = logoSizes[size];
  return (
    <TokenWithChain
      chainLogo={chainLogo[token.chain.id]}
      tokenLogo={<TokenLogo token={token} width={logoSize} height={logoSize} />}
      size={size}
      displayChainLogo={displayChainLogo}
    />
  );
}

export default ChainTokenLogo;
