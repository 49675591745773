const BlockOutside = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.16667 7.83594V11.5038C3.16667 11.8576 3.30714 12.1968 3.55719 12.4469C3.80724 12.6971 4.14638 12.8376 4.5 12.8376H11.5C11.8536 12.8376 12.1928 12.6971 12.4428 12.4469C12.6929 12.1968 12.8333 11.8576 12.8333 11.5038V7.83594M8 6.16872V3.16772M8 3.16772L6.5 4.83494M8 3.16772L9.5 4.83494M9.5 10.5035H6.5C6.32319 10.5035 6.15362 10.4332 6.0286 10.3082C5.90357 10.1831 5.83333 10.0135 5.83333 9.8366V8.83627C5.83333 8.6594 5.90357 8.48977 6.0286 8.36471C6.15362 8.23964 6.32319 8.16938 6.5 8.16938H9.5C9.67681 8.16938 9.84638 8.23964 9.9714 8.36471C10.0964 8.48977 10.1667 8.6594 10.1667 8.83627V9.8366C10.1667 10.0135 10.0964 10.1831 9.9714 10.3082C9.84638 10.4332 9.67681 10.5035 9.5 10.5035Z"
      stroke="currentColor"
      strokeWidth="1.00033"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BlockOutside;
