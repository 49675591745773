import { useMemo } from 'react';
import { assert } from '@chainflip/utils/assertion';
import { UDFCompatibleDatafeed } from '@/shared/lib/datafeed/udf-compatible-datafeed';

export const useTradingViewDatafeed = () => {
  const datafeedUrl = process.env.NEXT_PUBLIC_TRADINGVIEW_DATAFEED_URL;
  assert(datafeedUrl, 'NEXT_PUBLIC_TRADINGVIEW_DATAFEED_URL is not set');

  return useMemo(
    () => ({
      datafeed: new UDFCompatibleDatafeed(datafeedUrl, undefined, {
        maxResponseLength: 1000,
        expectedOrder: 'latestFirst',
      }),
    }),
    [datafeedUrl],
  );
};
