const NoSearch = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2685 11.5301C5.89099 11.8378 6.59197 12.0106 7.33333 12.0106C8.44189 12.0106 9.46017 11.6241 10.2607 10.9785L12.4798 13.1975C12.675 13.3928 12.9916 13.3928 13.1869 13.1975C13.3821 13.0023 13.3821 12.6857 13.1869 12.4904L10.9678 10.2714C11.6135 9.47081 12 8.45253 12 7.34397C12 6.60261 11.8271 5.90163 11.5195 5.27914L10.7606 6.03802C10.9153 6.44372 11 6.88396 11 7.34397C11 9.36903 9.35839 11.0106 7.33333 11.0106C6.87331 11.0106 6.43308 10.9259 6.02738 10.7712L5.2685 11.5301ZM10.2555 5.12888L10.9678 4.41658C10.758 4.15643 10.5209 3.91927 10.2607 3.70947L9.54843 4.42177C9.81505 4.62419 10.0531 4.86226 10.2555 5.12888ZM4.41113 9.55907C4.61355 9.82569 4.85162 10.0638 5.11824 10.2662L4.40594 10.9785C4.14579 10.7687 3.90863 10.5315 3.69883 10.2714L4.41113 9.55907ZM8.63929 3.9167C8.23359 3.76202 7.79336 3.67731 7.33333 3.67731C5.30829 3.67731 3.66667 5.31893 3.66667 7.34397C3.66667 7.804 3.75138 8.24423 3.90606 8.64993L3.14717 9.40882C2.83954 8.78632 2.66667 8.08534 2.66667 7.34397C2.66667 4.76664 4.756 2.67731 7.33333 2.67731C8.0747 2.67731 8.77568 2.85018 9.39818 3.15781L8.63929 3.9167Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.97977 11.6975C2.78451 11.5022 2.78451 11.1856 2.97977 10.9904L10.9798 2.99038C11.175 2.79512 11.4916 2.79512 11.6869 2.99038C11.8821 3.18564 11.8821 3.50222 11.6869 3.69749L3.68688 11.6975C3.49162 11.8927 3.17504 11.8927 2.97977 11.6975Z"
      fill="currentColor"
    />
  </svg>
);

export default NoSearch;
