const ChartUpward = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M3.16663 4.50954C3.16663 3.77316 3.76358 3.17621 4.49996 3.17621H11.5C12.2364 3.17621 12.8333 3.77316 12.8333 4.50954V11.5095C12.8333 12.2459 12.2364 12.8429 11.5 12.8429H4.49996C3.76358 12.8429 3.16663 12.2459 3.16663 11.5095V4.50954Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8334 9.67621L7.3334 8.00954L8.66674 9.67621L10.1667 6.67621"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChartUpward;
