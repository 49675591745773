const Refund = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.9984 18.2508C14.2345 18.2508 15.4429 17.8843 16.4707 17.1975C17.4985 16.5108 18.2996 15.5346 18.7726 14.3926C19.2456 13.2506 19.3694 11.9939 19.1283 10.7815C18.8871 9.56913 18.2918 8.45549 17.4178 7.58141C16.5437 6.70733 15.43 6.11208 14.2177 5.87092C13.0053 5.62976 11.7486 5.75353 10.6066 6.22658C9.46454 6.69963 8.48843 7.50071 7.80167 8.52851C7.11491 9.55632 6.74835 10.7647 6.74835 12.0008V14.3858M8.49835 13.5008L6.99835 15.2508L4.99835 13.5008"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 10H12C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12H14C14.5523 12 15 12.4477 15 13C15 13.5523 14.5523 14 14 14H11"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13 16L13 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Refund;
