import copyAnimationData from '@/shared/animations/copy.json';
import { copy } from '@/shared/utils';
import Lottie from '@/shared/utils/Lottie';
import IconButton from './IconButton';

interface CopyButtonProps {
  textToCopy: string;
  size?: Size;
}

type Size = 'small' | 'large';

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy, size = 'large' }): JSX.Element => (
  <IconButton
    size={size}
    onClick={() => {
      copy(textToCopy);
    }}
  >
    <Lottie
      as="button"
      className="h-4 w-4"
      animationData={copyAnimationData}
      autoplay={false}
      speed={1}
      loop={false}
      playOnce
    />
  </IconButton>
);

export default CopyButton;
