import classNames from 'classnames';

interface IconButtonProps {
  size?: Size;
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

type Size = 'small' | 'large';

const IconButton: React.FC<IconButtonProps> = ({
  size = 'large',
  children,
  onClick,
  className,
  disabled = false,
}) => {
  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <button
      type="button"
      className={classNames(
        size === 'small' ? 'h-5 w-5 min-w-[20px]' : 'h-6 w-6 min-w-[24px]',
        'flex h-6 w-6 items-center justify-center rounded border border-cf-gray-5 bg-cf-gray-4 text-cf-light-3 outline-none transition',
        !disabled && 'hover:bg-cf-gray-5 hover:text-cf-light-4',
        className,
      )}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
export default IconButton;
