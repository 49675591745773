const EyeHide = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2381_828)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3 10.3008C19.3 10.3008 19.3 10.2008 19.2 10.2008L17.9 10.9008C18.1 11.5008 18.2 11.9008 18.2 12.0008C18.2 12.1008 18.1 12.5008 17.9 13.1008C17.7 13.7008 17.3 14.4008 16.8 15.0008C15.8 16.3008 14.2 17.5008 11.7 17.5008C9.89999 17.5008 8.49999 16.9008 7.49999 16.0008L6.09999 16.7008C7.39999 18.0008 9.19999 19.0008 11.8 19.0008C14.8 19.0008 16.8 17.5008 18.1 16.0008C18.7 15.2008 19.1 14.4008 19.4 13.7008C19.6 13.0008 19.8 12.4008 19.8 12.0008C19.8 11.6008 19.6 11.0008 19.3 10.3008ZM11.8 5.00082C8.79999 5.00082 6.79999 6.50082 5.49999 8.00082C4.89999 8.80082 4.49999 9.60082 4.19999 10.3008C3.89999 11.0008 3.79999 11.6008 3.79999 12.0008C3.79999 12.4008 3.89999 13.0008 4.19999 13.7008C4.19999 13.7008 4.19999 13.8008 4.29999 13.8008L5.59999 13.1008C5.29999 12.5008 5.29999 12.1008 5.29999 12.0008C5.29999 11.9008 5.39999 11.5008 5.59999 10.9008C5.79999 10.3008 6.09999 9.60082 6.69999 9.00082C7.69999 7.70082 9.29999 6.50082 11.8 6.50082C13.6 6.50082 14.9 7.10082 15.9 8.00082L17.3 7.30082C16.1 6.00082 14.3 5.00082 11.8 5.00082Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4 14.7008C10.8 14.9008 11.2 15.0008 11.8 15.0008C13.3 15.0008 14.5 13.9008 14.8 12.5008L10.4 14.7008ZM11.8 9.00082C10.3 9.00082 8.99999 10.1008 8.79999 11.5008L13.2 9.30082C12.7 9.10082 12.3 9.00082 11.8 9.00082Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 8.20083C20.5 8.40083 20.3 8.60083 20.1 8.70083L18.9 9.30083L17.5 10.0008L14.7 11.4008L13.2 12.1008L10.9 13.2008L9.50001 14.0008L6.90001 15.3008L5.50001 16.0008L4.10001 16.7008C3.70001 16.9008 3.30001 16.7008 3.10001 16.4008C2.90001 16.0008 3.10001 15.6008 3.40001 15.4008L4.60001 14.8008L6.00001 14.0008L8.80001 12.6008L10.2 11.9008L12.5 10.7008L14 10.0008L16.6 8.70083L18 8.00083L19.4 7.30083C19.6 7.20083 19.9 7.20083 20.1 7.30083C20.2 7.40083 20.4 7.50083 20.5 7.60083C20.5 7.80083 20.5 8.00083 20.5 8.20083Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2381_828">
        <rect width="17.5" height="14" fill="currentColor" transform="translate(3 5.00082)" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeHide;
