import type React from 'react';
import dynamic from 'next/dynamic';

// https://stackoverflow.com/questions/53139884/next-js-disable-server-side-rendering-on-some-pages
export const DisableSSR = dynamic(
  () => Promise.resolve((props: { children: React.ReactNode }) => props.children),
  {
    ssr: false,
  },
);
