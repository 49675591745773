import Link from 'next/link';
import Button from './flip-ui-kit/Button';
import { BlockOutsideIcon } from '../icons/small';

export default function ViewEventButton({
  event,
  baseUrl,
}: {
  event: { indexInBlock: number; blockId: number } | null | undefined;
  baseUrl?: string;
}) {
  const path = `/events/${event?.blockId}-${event?.indexInBlock}`;

  const href = baseUrl ? new URL(path, baseUrl) : path;

  return (
    <Link href={href} target={baseUrl ? '_blank' : undefined}>
      <Button
        size="small"
        type="secondary-standard"
        className="max-w-fit gap-x-1 whitespace-nowrap"
      >
        <div>
          <BlockOutsideIcon className="text-cf-blue-2" />
        </div>
        <span className="text-12">View Event</span>
      </Button>
    </Link>
  );
}
