import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChainflipLogo } from '@/shared/assets/chainflip-logo';
import { BaseNavbar, Link } from '@/shared/components';
import { ConnectButton } from '@/shared/components/RainbowKit';
import {
  DotsHorizontalIcon,
  DropletIcon,
  GlobeIcon,
  LightBulbIcon as LightBulbIconLg,
  ChatIcon as ChatIconLg,
  HelpIcon as HelpIconLg,
} from '@/shared/icons/large';
import { LightBulbIcon, ChatIcon, HelpIcon } from '@/shared/icons/small';

export const DropdownItem = (): JSX.Element => (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild>
      <button
        type="button"
        className="flex items-center justify-center rounded-md border border-cf-gray-4 bg-black text-cf-light-2 outline-none transition hover:bg-cf-gray-3 hover:text-cf-white data-[state=open]:bg-cf-gray-3 data-[state=open]:text-white"
      >
        <DotsHorizontalIcon className="h-[38px] w-[38px]" />
      </button>
    </DropdownMenu.Trigger>

    <DropdownMenu.Portal>
      <DropdownMenu.Content
        className="z-20 space-y-2 rounded-md border border-cf-gray-4 bg-cf-gray-3 p-2.5 text-14 text-cf-light-3"
        sideOffset={10}
        align="end"
      >
        {[
          {
            item: 'Help & Support',
            Icon: HelpIcon,
            href: 'https://discord.com/channels/824147014140952596/1159396062872743987',
          },
          {
            item: 'Leave feedback',
            Icon: ChatIcon,
            href: 'https://discord.com/channels/824147014140952596/1140570867496132649',
          },
          {
            item: 'Request a feature',
            Icon: LightBulbIcon,
            href: 'https://chainflip.canny.io/feature-requests',
          },
        ].map(({ item, Icon, href }) => (
          <DropdownMenu.Item
            key={item}
            className="whitespace-nowrap rounded-md p-1 outline-none transition hover:bg-cf-gray-4 hover:text-white"
          >
            <a href={href} target="_blank" className="flex items-center gap-x-2" rel="noreferrer">
              <Icon />
              {item}
            </a>
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
);

export const Navbar = (): JSX.Element => (
  <>
    {/* Mobile view */}
    <div className="z-10 flex flex-row items-center justify-between p-4 pb-2 md:hidden">
      <Link href="/">
        <ChainflipLogo className="w-[150px]" />
      </Link>
      <div className="flex flex-row gap-2">
        <ConnectButton />
        <DropdownItem />
      </div>
    </div>
    {/* Desktop view */}
    <BaseNavbar
      className="hidden md:flex"
      logoHref="/"
      desktopContent={
        <div className="flex flex-row gap-6">
          <Link
            href={new URL('/', process.env.NEXT_PUBLIC_LP_URL).toString()}
            target="_blank"
            className="flex items-center space-x-1 text-14"
          >
            <DropletIcon />
            <span>Provide Liquidity</span>
          </Link>
          <Link
            href={new URL('/swaps', process.env.NEXT_PUBLIC_EXPLORER_URL).toString()}
            target="_blank"
            className="flex items-center space-x-1 text-14"
          >
            <GlobeIcon />
            <span>Explorer</span>
          </Link>
          <div className="flex">
            <ConnectButton />
          </div>
          <DropdownItem />
        </div>
      }
      mobileContent={
        <div className="flex flex-col gap-6">
          <Link
            href={new URL('/', process.env.NEXT_PUBLIC_LP_URL).toString()}
            target="_blank"
            className="flex items-center space-x-1 text-14"
          >
            <DropletIcon />
            <span>Provide Liquidity</span>
          </Link>
          <Link
            href={new URL('/swaps', process.env.NEXT_PUBLIC_EXPLORER_URL).toString()}
            target="_blank"
            className="flex items-center space-x-1 text-14"
          >
            <GlobeIcon />
            <span>Explorer</span>
          </Link>
          <div className="flex">
            <ConnectButton />
          </div>
          <Link
            href="https://discord.com/channels/824147014140952596/1159396062872743987"
            target="_blank"
            className="flex items-center space-x-1 text-14"
          >
            <HelpIconLg />
            <span>Help & Support</span>
          </Link>
          <Link
            href="https://discord.com/channels/824147014140952596/1140570867496132649"
            target="_blank"
            className="flex items-center space-x-1 text-14"
          >
            <ChatIconLg />
            <span>Leave Feedback</span>
          </Link>
          <Link
            href="https://chainflip.canny.io/feature-requests"
            target="_blank"
            className="flex items-center space-x-1 text-14"
          >
            <LightBulbIconLg />
            <span>Request a Feature</span>
          </Link>
        </div>
      }
    />
  </>
);
