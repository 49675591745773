const Broker = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="12.0771"
      y1="13.0974"
      x2="12.0771"
      y2="17.5974"
      stroke="currentColor"
      strokeWidth="1.6875"
    />
    <line
      x1="12.0771"
      y1="6.34743"
      x2="12.0771"
      y2="10.8474"
      stroke="currentColor"
      strokeWidth="1.6875"
    />
    <line
      x1="6.73332"
      y1="12.2537"
      x2="10.3574"
      y2="12.2537"
      stroke="currentColor"
      strokeWidth="1.6875"
    />
    <line
      x1="13.4833"
      y1="12.2537"
      x2="17.9833"
      y2="12.2537"
      stroke="currentColor"
      strokeWidth="1.6875"
    />
    <path
      d="M13.4062 5.25C13.4062 5.62296 13.2581 5.98065 12.9944 6.24437C12.7306 6.50809 12.373 6.65625 12 6.65625C11.627 6.65625 11.2694 6.50809 11.0056 6.24437C10.7419 5.98065 10.5938 5.62296 10.5938 5.25C10.5938 4.87704 10.7419 4.51935 11.0056 4.25563C11.2694 3.99191 11.627 3.84375 12 3.84375C12.373 3.84375 12.7306 3.99191 12.9944 4.25563C13.2581 4.51935 13.4062 4.87704 13.4062 5.25ZM20.1562 12C20.1562 12.373 20.0081 12.7306 19.7444 12.9944C19.4806 13.2581 19.123 13.4062 18.75 13.4062C18.377 13.4062 18.0194 13.2581 17.7556 12.9944C17.4919 12.7306 17.3438 12.373 17.3438 12C17.3438 11.627 17.4919 11.2694 17.7556 11.0056C18.0194 10.7419 18.377 10.5938 18.75 10.5938C19.123 10.5938 19.4806 10.7419 19.7444 11.0056C20.0081 11.2694 20.1562 11.627 20.1562 12ZM6.65625 12C6.65625 12.373 6.50809 12.7306 6.24437 12.9944C5.98065 13.2581 5.62296 13.4062 5.25 13.4062C4.87704 13.4062 4.51935 13.2581 4.25563 12.9944C3.99191 12.7306 3.84375 12.373 3.84375 12C3.84375 11.627 3.99191 11.2694 4.25563 11.0056C4.51935 10.7419 4.87704 10.5938 5.25 10.5938C5.62296 10.5938 5.98065 10.7419 6.24437 11.0056C6.50809 11.2694 6.65625 11.627 6.65625 12ZM13.4062 18.75C13.4063 19.123 13.2581 19.4806 12.9944 19.7444C12.7306 20.0081 12.373 20.1562 12 20.1562C11.627 20.1562 11.2694 20.0081 11.0056 19.7444C10.7419 19.4806 10.5938 19.123 10.5938 18.75C10.5938 18.377 10.7419 18.0194 11.0056 17.7556C11.2694 17.4919 11.627 17.3438 12 17.3438C12.373 17.3438 12.7306 17.4919 12.9944 17.7556C13.2581 18.0194 13.4062 18.377 13.4062 18.75Z"
      stroke="currentColor"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3981 13.3981C13.7689 13.0273 13.9773 12.5244 13.9773 12C13.9773 11.4756 13.7689 10.9727 13.3981 10.6019C13.0273 10.2311 12.5244 10.0227 12 10.0227C11.4756 10.0227 10.9727 10.2311 10.6019 10.6019C10.2311 10.9727 10.0227 11.4756 10.0227 12C10.0227 12.5244 10.2311 13.0273 10.6019 13.3981C10.9727 13.7689 11.4756 13.9773 12 13.9773C12.5244 13.9773 13.0273 13.7689 13.3981 13.3981Z"
      stroke="white" // Please don't change, this is purposefuly white for pages/channels/[id].tsx
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Broker;
