import { SelectedDropdown } from '@/shared/components';
import { type SelectedDropdownOption } from '@/shared/components/flip-ui-kit/SelectedDropdown';
import { CalendarIcon } from '@/shared/icons/small';

export const timeframeOptions: SelectedDropdownOption<number>[] = [
  {
    icon: CalendarIcon,
    label: 'Last 30 days',
    value: 30,
  },
  {
    icon: CalendarIcon,
    label: 'Last 7 days',
    value: 7,
  },
  {
    icon: CalendarIcon,
    label: 'Last 24 hours',
    value: 1,
  },
  // TODO: requires a bunch of work to handle querying more data as the user scrolls
  // through the chart
  // {
  //   icon: CalendarIcon,
  //   label: 'All time',
  //   value: Infinity,
  // },
];

export const TimeframeDropdown = ({
  selected,
  setSelected,
  className,
}: {
  selected: SelectedDropdownOption<number>;
  setSelected: (option: SelectedDropdownOption<number>) => void;
  className?: string;
}) => (
  <SelectedDropdown
    showOptionIcon={false}
    options={timeframeOptions}
    selected={selected}
    setSelected={setSelected}
    className={className}
  />
);
