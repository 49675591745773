const RightDouble = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M7.7478 8.75104L11.2476 12.0008L7.7478 15.2506"
      stroke="currentColor"
      strokeWidth="1.4999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7475 8.75104L16.2472 12.0008L12.7475 15.2506"
      stroke="currentColor"
      strokeWidth="1.4999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default RightDouble;
