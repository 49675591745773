const TimeSpeed = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M9.02759 18.5009C9.97968 19.0147 11.0489 19.2727 12.1305 19.2494C13.2122 19.2262 14.2693 18.9225 15.1984 18.3682C16.1276 17.8138 16.8969 17.0278 17.4311 16.087C17.9653 15.1462 18.2462 14.0828 18.2462 13.0009C18.2462 11.9189 17.9653 10.8555 17.4311 9.91472C16.8969 8.9739 16.1276 8.18787 15.1984 7.63354C14.2693 7.07921 13.2122 6.77556 12.1305 6.7523C11.0489 6.72904 9.97968 6.98697 9.02759 7.50085M4.74859 10.0009H8.24859M4.74859 16.0009H8.24859M4.74859 13.0009H6.24859M16.4986 8.50085L17.2486 7.75085M11.9986 6.50085V4.75085M11.9986 4.75085H9.74859M11.9986 4.75085H14.2486M14.2486 10.7509L11.7486 13.2509"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TimeSpeed;
