import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export default function useQueryParam<const T>({
  name,
  parse,
  stringify = (value: T) => String(value),
  defaultValue,
  scroll,
}: {
  name?: string;
  parse: (value: string) => T;
  stringify?: (value: T) => string;
  defaultValue: T;
  scroll?: boolean;
}) {
  const router = useRouter();

  const [value, setValue] = useState<T>(() => {
    if (!name) return defaultValue;
    // read from window.location because router.query is not initialized during initial render
    const searchParams = new URLSearchParams(window.location.search);
    const queryValue = searchParams.get(name);
    return queryValue ? parse(queryValue) : defaultValue;
  });

  useEffect(() => {
    if (!name) return;
    const { [name]: queryValue = '', ...newQuery } = router.query;
    const stringValue = value !== defaultValue ? stringify(value) : '';

    if (router.isReady && stringValue !== queryValue) {
      if (stringValue !== '') newQuery[name] = stringValue; // only add non-default values to query
      router.replace({ pathname: router.pathname, query: newQuery }, undefined, {
        scroll,
      });
    }
  }, [name, value, router.query]);

  return [value, setValue] as const;
}
