export const FancyDollarDisplay = ({ value }: { value: string }) => {
  const [whole, decimal] = value.split('.');

  return (
    <div className="font-aeonikMedium">
      <span className="text-24 text-cf-white">{whole}</span>
      <span className="text-24">.</span>
      <span className="text-12 text-cf-light-2">{decimal}</span>
    </div>
  );
};
