const FeeIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1 3.5C14.2 3.5 12.5 5.1 12.5 7C12.5 8.9 14.1 10.5 16 10.5C17.9 10.5 19.5 8.9 19.5 7C19.5 5.1 18 3.5 16.1 3.5ZM11 7C11 6.8 11 6.7 11 6.6H6.5V18.8L6.9 18.5L7 18.4C7.2 18.2 7.6 18 7.9 18C8.1 18 8.3 18 8.5 18.1C8.6 18.1 8.9 18.2 9 18.4L9.9 19.2L10.7 18.5C11.2 18 11.6 18 11.9 18C12.3 18 12.7 18.2 12.9 18.3C13 18.3 13 18.4 13.1 18.4L14 19.2L14.9 18.4C15.3 18 15.8 18 16 18C16.2 18 16.4 18 16.6 18.1C16.7 18.1 16.9 18.2 17.1 18.4L17.5 18.7V11.8C17 11.9 16.5 12 16.1 12C13.2 12 11 9.7 11 7ZM11.2 5C11.3 5 11.3 5 11.4 5C12.2 3.3 14 2 16.1 2C18.9 2 21.1 4.2 21.1 7C21.1 8.7 20.3 10.2 18.9 11.1V11.2V20.2C18.9 20.5 18.7 20.7 18.5 20.9C18.2 21 17.9 21 17.7 20.8L16.1 19.6H16C16 19.6 16 19.6 15.9 19.6L14.5 20.8C14.2 21 13.8 21 13.5 20.8L12.3 19.7C12.3 19.7 12.2 19.7 12.2 19.6H12.1C12 19.6 12 19.6 11.9 19.6L10.6 20.8C10.3 21.1 9.9 21.1 9.6 20.8L8.2 19.6H8.1C8.1 19.6 8 19.6 7.9 19.7L6.4 20.9C6 21.1 5.7 21.1 5.4 21C5.2 20.9 5 20.6 5 20.3V5.8C5 5.4 5.3 5 5.7 5H11.2ZM8.5 11.8C8.5 11.4 8.8 11 9.3 11H11.3C11.7 11.1 12 11.4 12 11.8C12 12.2 11.7 12.6 11.2 12.6H9.2C8.8 12.6 8.5 12.2 8.5 11.8ZM8.5 14.8C8.5 14.4 8.8 14 9.3 14H14.3C14.7 14 15.1 14.3 15.1 14.8C15.1 15.3 14.8 15.6 14.3 15.6H9.3C8.8 15.6 8.5 15.2 8.5 14.8ZM16.5 5C16.5 4.8 16.3 4.6 16.1 4.6C15.9 4.6 15.7 4.8 15.7 5C15 5.1 14.5 5.6 14.5 6.3C14.5 7 15.1 7.6 15.8 7.6H16.4C16.5 7.6 16.5 7.6 16.5 7.6C16.5 7.6 16.5 7.6 16.5 7.7C16.5 7.8 16.5 7.8 16.5 7.8C16.5 7.8 16.5 7.8 16.4 7.8H15.1C14.8 7.8 14.5 8.1 14.5 8.4C14.5 8.7 14.7 9 15.1 9H15.7C15.7 9.2 15.9 9.4 16.1 9.4C16.3 9.4 16.5 9.2 16.5 9C17.2 8.9 17.6 8.4 17.6 7.7C17.6 7 17 6.4 16.3 6.4H15.7C15.6 6.4 15.6 6.4 15.6 6.4C15.6 6.4 15.6 6.4 15.6 6.3C15.6 6.2 15.6 6.2 15.6 6.2C15.6 6.2 15.6 6.2 15.7 6.2H17C17.3 6.2 17.6 5.9 17.6 5.6C17.6 5.3 17.4 5 17.1 5H16.5Z"
      fill="currentColor"
    />
  </svg>
);

export default FeeIcon;
