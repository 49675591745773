import { differenceInTimeAgo } from '@chainflip/utils/date';
import { Tooltip } from './Tooltip';

export default function AgeWithTooltip({ timestamp }: { timestamp: string }) {
  return (
    <Tooltip content={new Date(timestamp).toLocaleString()} pointer={false}>
      <div>{differenceInTimeAgo(new Date(timestamp).toUTCString())}</div>
    </Tooltip>
  );
}
