import { type RpcMethod, type RpcResult, type RpcParams } from '@chainflip/rpc/types';
import {
  type UseQueryOptions,
  useQuery,
  type DefaultError,
  type QueryKey,
} from '@tanstack/react-query';
import makeRpcRequest from '../utils/rpc';

type Opts<M extends RpcMethod, TError, TData, TQueryKey extends QueryKey> = Omit<
  UseQueryOptions<RpcResult<M>, TError, TData, TQueryKey> & { method: M; args: RpcParams[M] },
  'queryKey' | 'queryFn'
>;

export default function useRpcRequest<
  const M extends RpcMethod,
  TError = DefaultError,
  TData = RpcResult<M>,
>({ method, args, ...rest }: Opts<M, TError, TData, QueryKey>) {
  return useQuery<RpcResult<M>, TError, TData, QueryKey>({
    ...rest,
    queryKey: [method, ...args],
    queryFn: () => makeRpcRequest(method, ...args),
  });
}
