const Grid = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.16669 3.83341C3.16669 3.46523 3.46517 3.16675 3.83335 3.16675H6.16669C6.53487 3.16675 6.83335 3.46523 6.83335 3.83341V6.16675C6.83335 6.53493 6.53487 6.83341 6.16669 6.83341H3.83335C3.46517 6.83341 3.16669 6.53493 3.16669 6.16675V3.83341Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.16669 9.83341C3.16669 9.46521 3.46517 9.16675 3.83335 9.16675H6.16669C6.53487 9.16675 6.83335 9.46521 6.83335 9.83341V12.1667C6.83335 12.5349 6.53487 12.8334 6.16669 12.8334H3.83335C3.46517 12.8334 3.16669 12.5349 3.16669 12.1667V9.83341Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16669 3.83341C9.16669 3.46523 9.46515 3.16675 9.83335 3.16675H12.1667C12.5349 3.16675 12.8334 3.46523 12.8334 3.83341V6.16675C12.8334 6.53493 12.5349 6.83341 12.1667 6.83341H9.83335C9.46515 6.83341 9.16669 6.53493 9.16669 6.16675V3.83341Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16669 9.83341C9.16669 9.46521 9.46515 9.16675 9.83335 9.16675H12.1667C12.5349 9.16675 12.8334 9.46521 12.8334 9.83341V12.1667C12.8334 12.5349 12.5349 12.8334 12.1667 12.8334H9.83335C9.46515 12.8334 9.16669 12.5349 9.16669 12.1667V9.83341Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Grid;
