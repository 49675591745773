const Puzzle = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 12.1667H10.1667C10.9031 12.1667 11.5 11.5697 11.5 10.8333V9.3496C11.5 9.3406 11.5073 9.33333 11.5163 9.33333C12.2437 9.33333 12.8333 8.74367 12.8333 8.01627V7.98373C12.8333 7.25633 12.2437 6.66667 11.5163 6.66667C11.5073 6.66667 11.5 6.65939 11.5 6.65041V5.16667C11.5 4.43029 10.9031 3.83333 10.1667 3.83333H8.68626C8.67546 3.83333 8.66666 3.82455 8.66666 3.81373C8.66666 3.08817 8.07846 2.5 7.35293 2.5H7.31373C6.58817 2.5 6 3.08817 6 3.81373C6 3.82455 5.99122 3.83333 5.98039 3.83333H4.5C3.76362 3.83333 3.16666 4.43029 3.16666 5.16667V10.8333C3.16666 11.5697 3.76362 12.1667 4.5 12.1667Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Puzzle;
