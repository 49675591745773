const Gas = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.73016 12.2078C9.73016 12.5012 9.96801 12.739 10.2614 12.739C10.5548 12.739 10.7926 12.5012 10.7926 12.2078H9.73016ZM3.70968 12.2078C3.70968 12.5012 3.94751 12.739 4.2409 12.739C4.53428 12.739 4.77212 12.5012 4.77212 12.2078H3.70968ZM5.65748 4.06244H8.8448V3H5.65748V4.06244ZM9.73016 4.9478V12.2078H10.7926V4.9478H9.73016ZM4.77212 12.2078V4.9478H3.70968V12.2078H4.77212ZM8.8448 4.06244C9.3338 4.06244 9.73016 4.45883 9.73016 4.9478H10.7926C10.7926 3.87206 9.92055 3 8.8448 3V4.06244ZM5.65748 3C4.58174 3 3.70968 3.87206 3.70968 4.9478H4.77212C4.77212 4.45883 5.1685 4.06244 5.65748 4.06244V3Z"
      fill="currentColor"
    />
    <path
      d="M6.36577 5.6561V5.12488C6.07239 5.12488 5.83455 5.36272 5.83455 5.6561H6.36577ZM8.1365 5.6561H8.66772C8.66772 5.36272 8.42988 5.12488 8.1365 5.12488V5.6561ZM8.1365 8.13512V8.66634C8.42988 8.66634 8.66772 8.4285 8.66772 8.13512H8.1365ZM6.36577 8.13512H5.83455C5.83455 8.4285 6.07239 8.66634 6.36577 8.66634V8.13512ZM6.36577 6.18732H8.1365V5.12488H6.36577V6.18732ZM7.60528 5.6561V8.13512H8.66772V5.6561H7.60528ZM6.89699 8.13512V5.6561H5.83455V8.13512H6.89699ZM6.36577 8.66634H8.1365V7.6039H6.36577V8.66634Z"
      fill="currentColor"
    />
    <path
      d="M3.53122 11.8523C3.23784 11.8523 3 12.0901 3 12.3835C3 12.6769 3.23784 12.9147 3.53122 12.9147V11.8523ZM10.9683 12.9147C11.2617 12.9147 11.4995 12.6769 11.4995 12.3835C11.4995 12.0901 11.2617 11.8523 10.9683 11.8523V12.9147ZM3.53122 12.9147H10.9683V11.8523H3.53122V12.9147Z"
      fill="currentColor"
    />
    <path
      d="M10.4371 9.55311C10.1437 9.55311 9.90585 9.79095 9.90585 10.0843C9.90585 10.3777 10.1437 10.6155 10.4371 10.6155V9.55311ZM12.5638 5.6333L12.1667 5.28037L12.5638 5.6333ZM14.1985 4.59383C14.3934 4.37456 14.3737 4.03879 14.1544 3.84387C13.9351 3.64896 13.5993 3.66871 13.4044 3.88799L14.1985 4.59383ZM10.4371 10.6155H10.9683V9.55311H10.4371V10.6155ZM12.9161 8.66774V6.10386H11.8537V8.66774H12.9161ZM12.9608 5.98622L14.1985 4.59383L13.4044 3.88799L12.1667 5.28037L12.9608 5.98622ZM12.9161 6.10386C12.9161 6.06052 12.932 6.01866 12.9608 5.98622L12.1667 5.28037C11.9651 5.50727 11.8537 5.80029 11.8537 6.10386H12.9161ZM10.9683 10.6155C12.044 10.6155 12.9161 9.7435 12.9161 8.66774H11.8537C11.8537 9.15675 11.4573 9.55311 10.9683 9.55311V10.6155Z"
      fill="currentColor"
    />
  </svg>
);

export default Gas;
