const DocIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.16667 12.844H10.8333C11.5697 12.844 12.1667 12.247 12.1667 11.5106V6.01064L9.33334 3.17731H5.16667C4.43029 3.17731 3.83334 3.77426 3.83334 4.51064V11.5106C3.83334 12.247 4.43029 12.844 5.16667 12.844Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 6.17727H9.16666V3.34393"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.5 10.1773H9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.5 8.17731H9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default DocIcon;
