const Chunks = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.9984 6.25082C12.9984 6.66503 12.6625 7.00082 12.2484 7.00082C11.8342 7.00082 11.4984 6.66503 11.4984 6.25082C11.4984 5.83661 11.8342 5.50082 12.2484 5.50082C12.6625 5.50082 12.9984 5.83661 12.9984 6.25082Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9984 12.2508C12.9984 12.665 12.6625 13.0008 12.2484 13.0008C11.8342 13.0008 11.4984 12.665 11.4984 12.2508C11.4984 11.8367 11.8342 11.5008 12.2484 11.5008C12.6625 11.5008 12.9984 11.8367 12.9984 12.2508Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9984 6.25082C18.9984 6.66503 18.6625 7.00082 18.2484 7.00082C17.8342 7.00082 17.4984 6.66503 17.4984 6.25082C17.4984 5.83661 17.8342 5.50082 18.2484 5.50082C18.6625 5.50082 18.9984 5.83661 18.9984 6.25082Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9984 12.2508C18.9984 12.665 18.6625 13.0008 18.2484 13.0008C17.8342 13.0008 17.4984 12.665 17.4984 12.2508C17.4984 11.8367 17.8342 11.5008 18.2484 11.5008C18.6625 11.5008 18.9984 11.8367 18.9984 12.2508Z"
      stroke="#909090"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99835 6.25082C6.99835 6.66503 6.66256 7.00082 6.24835 7.00082C5.83414 7.00082 5.49835 6.66503 5.49835 6.25082C5.49835 5.83661 5.83414 5.50082 6.24835 5.50082C6.66256 5.50082 6.99835 5.83661 6.99835 6.25082Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99835 12.2508C6.99835 12.665 6.66256 13.0008 6.24835 13.0008C5.83414 13.0008 5.49835 12.665 5.49835 12.2508C5.49835 11.8367 5.83414 11.5008 6.24835 11.5008C6.66256 11.5008 6.99835 11.8367 6.99835 12.2508Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9984 18.2508C12.9984 18.665 12.6625 19.0008 12.2484 19.0008C11.8342 19.0008 11.4984 18.665 11.4984 18.2508C11.4984 17.8367 11.8342 17.5008 12.2484 17.5008C12.6625 17.5008 12.9984 17.8367 12.9984 18.2508Z"
      stroke="#909090"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9984 18.2508C18.9984 18.665 18.6625 19.0008 18.2484 19.0008C17.8342 19.0008 17.4984 18.665 17.4984 18.2508C17.4984 17.8367 17.8342 17.5008 18.2484 17.5008C18.6625 17.5008 18.9984 17.8367 18.9984 18.2508Z"
      stroke="#909090"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99835 18.2508C6.99835 18.665 6.66256 19.0008 6.24835 19.0008C5.83414 19.0008 5.49835 18.665 5.49835 18.2508C5.49835 17.8367 5.83414 17.5008 6.24835 17.5008C6.66256 17.5008 6.99835 17.8367 6.99835 18.2508Z"
      stroke="#909090"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chunks;
