import { FLIP_SYMBOL } from '@/shared/utils';
import Pill from '../flip-ui-kit/Pill';
import { Tooltip } from '../molecules/Tooltip';

type Badge = 'Authority' | 'Backup' | 'Bidding' | 'Qualified' | 'Keyholder';
type Flag = 'isCurrentAuthority' | 'isCurrentBackup' | 'isBidding' | 'isQualified' | 'isKeyholder';

export const ValidatorBadges = ({
  cacheValidator,
  badges,
  halfOpacity = false,
}: {
  cacheValidator: ({ [K in Flag]: boolean } & { apyBp?: number | null | undefined }) | undefined;
  badges?: Badge[];
  halfOpacity?: boolean;
}) => {
  if (!cacheValidator) return null;

  const isBackupEarningRewards = cacheValidator.isCurrentBackup && Boolean(cacheValidator.apyBp);

  const validator = {
    ...cacheValidator,
    isCurrentBackup: isBackupEarningRewards,
  };

  const opacity = halfOpacity ? 'opacity-50' : undefined;

  return (
    <>
      {(
        [
          {
            name: 'Authority' as const,
            keys: ['isCurrentAuthority'] as const,
            color: 'green',
            tooltipContent:
              'Authorities are responsible for block authorship, consensus, witnessing, threshold signing and transaction broadcasting. In return, they earn FLIP.',
          },
          {
            name: 'Backup' as const,
            keys: ['isCurrentBackup', 'isQualified'] as const,
            color: 'pink',
            tooltipContent: `A Backup is among the highest bidding non-Authorities, earning ${FLIP_SYMBOL} in return.`,
          },
          {
            name: 'Bidding' as const,
            keys: ['isBidding'] as const,
            color: 'neutral',
            tooltipContent:
              'A Bidding validator is indicating its intention to participate in the next auction.',
          },
          {
            name: 'Qualified' as const,
            keys: ['isQualified'] as const,
            color: 'neutral',
            tooltipContent: 'A Qualified validator can participate in auctions.',
          },
          {
            name: 'Keyholder' as const,
            keys: ['isKeyholder'] as const,
            color: 'neutral',
            tooltipContent:
              'A Keyholder holds a fragment of an unexpired threshold signing key from a previous Authority Set.',
          },
        ] as const
      )
        .filter(
          ({ name, keys }) =>
            (badges?.includes(name) ?? true) && keys.every((key) => validator[key]),
        )
        .map(({ color, tooltipContent, name }) => (
          <Pill
            key={name}
            color={color}
            tooltipContent={tooltipContent}
            tooltipLink="https://docs.chainflip.io/concepts/validator-network/validator-types-and-states"
            text={name}
            opacity={opacity}
          />
        ))}
      {cacheValidator.isCurrentAuthority && !cacheValidator.isBidding && (
        <Tooltip
          content="A Retiring (non-bidding) validator is indicating its intention to retire from the next auction"
          link="https://docs.chainflip.io/concepts/validator-network/validator-types-and-states"
        >
          <Pill color="red" text="Retiring" opacity={opacity} />
        </Tooltip>
      )}
    </>
  );
};
