const WriteNote = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 10.5V10.8333C11.5 11.9379 10.6046 12.8333 9.5 12.8333H5.16667C4.0621 12.8333 3.16667 11.9379 3.16667 10.8333V5.16666C3.16667 4.06209 4.0621 3.16666 5.16667 3.16666H6.83334"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83333 8.16666L7.34827 8.04539C7.30567 8.21579 7.3556 8.39599 7.4798 8.52019C7.604 8.64439 7.7842 8.69433 7.9546 8.65173L7.83333 8.16666ZM12.1392 4.79819L9.95047 6.98693L10.6575 7.69406L12.8463 5.5053L12.1392 4.79819ZM9.52333 7.22879L7.71207 7.68159L7.9546 8.65173L9.76587 8.19893L9.52333 7.22879ZM8.3184 8.28793L8.7712 6.47667L7.80107 6.23414L7.34827 8.04539L8.3184 8.28793ZM9.01307 6.04955L11.2018 3.8608L10.4947 3.15369L8.30594 5.34244L9.01307 6.04955ZM8.7712 6.47667C8.8116 6.31501 8.8952 6.16738 9.01307 6.04955L8.30594 5.34244C8.05994 5.58843 7.88547 5.89665 7.80107 6.23414L8.7712 6.47667ZM9.95047 6.98693C9.8326 7.10479 9.685 7.18839 9.52333 7.22879L9.76587 8.19893C10.1033 8.11453 10.4115 7.94006 10.6575 7.69406L9.95047 6.98693ZM12.1392 3.8608C12.3981 4.11965 12.3981 4.53934 12.1392 4.79819L12.8463 5.5053C13.4957 4.85592 13.4957 3.80307 12.8463 3.15369L12.1392 3.8608ZM12.8463 3.15369C12.1969 2.50431 11.1441 2.50431 10.4947 3.15369L11.2018 3.8608C11.4607 3.60195 11.8803 3.60195 12.1392 3.8608L12.8463 3.15369Z"
      fill="currentColor"
    />
    <path
      d="M5.16667 10.1667H9.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16667 8.16666H6.16667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16667 6.16666H6.16667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WriteNote;
