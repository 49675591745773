const ChartCheck = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M4.36892 8.17096H4.0241C3.64321 8.17096 3.33444 8.47973 3.33444 8.86061V12.6537C3.33444 13.0346 3.64321 13.3434 4.0241 13.3434H4.36892C4.74981 13.3434 5.05858 13.0346 5.05858 12.6537V8.86061C5.05858 8.47973 4.74981 8.17096 4.36892 8.17096Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4379 3.34338H11.0931C10.7122 3.34338 10.4034 3.65216 10.4034 4.03304V12.6537C10.4034 13.0346 10.7122 13.3434 11.0931 13.3434H11.4379C11.8188 13.3434 12.1275 13.0346 12.1275 12.6537V4.03304C12.1275 3.65216 11.8188 3.34338 11.4379 3.34338Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.98961 6.10199H7.64478C7.26388 6.10199 6.95512 6.41076 6.95512 6.79164V12.6537C6.95512 13.0346 7.26388 13.3434 7.64478 13.3434H7.98961C8.3705 13.3434 8.67926 13.0346 8.67926 12.6537V6.79164C8.67926 6.41076 8.3705 6.10199 7.98961 6.10199Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33444 4.89512L4.36892 5.9296C4.88617 4.37788 6.43789 3.51581 6.43789 3.51581"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChartCheck;
