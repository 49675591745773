const Receipt = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.16667 3.16666H3.83333V12.8333L4.89933 12.0047C5.02149 11.9096 5.17292 11.86 5.32764 11.8644C5.48237 11.8687 5.63075 11.9268 5.74733 12.0287L6.66667 12.8333L7.56067 12.0507C7.68224 11.9441 7.83837 11.8854 8 11.8854C8.16163 11.8854 8.31776 11.9441 8.43933 12.0507L9.33333 12.8333L10.2527 12.0287C10.3692 11.9268 10.5176 11.8687 10.6724 11.8644C10.8271 11.86 10.9785 11.9096 11.1007 12.0047L12.1667 12.8333V6.83332M9.16667 3.16666L12.1667 6.83332M9.16667 3.16666V5.49999C9.16667 5.85361 9.30714 6.19275 9.55719 6.4428C9.80724 6.69285 10.1464 6.83332 10.5 6.83332H12.1667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Receipt;
