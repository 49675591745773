const Volume = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M6.24835 8.75082H5.74835C5.19607 8.75082 4.74835 9.19854 4.74835 9.75082V15.2508C4.74835 15.8031 5.19607 16.2508 5.74835 16.2508H6.24835C6.80063 16.2508 7.24835 15.8031 7.24835 15.2508V9.75082C7.24835 9.19854 6.80063 8.75082 6.24835 8.75082Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2484 4.75082H11.7484C11.1961 4.75082 10.7484 5.19854 10.7484 5.75082V15.2508C10.7484 15.8031 11.1961 16.2508 11.7484 16.2508H12.2484C12.8007 16.2508 13.2484 15.8031 13.2484 15.2508V5.75082C13.2484 5.19854 12.8007 4.75082 12.2484 4.75082Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.74835 19.2508H19.2484"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2484 8.75082H17.7484C17.1961 8.75082 16.7484 9.19854 16.7484 9.75082V15.2508C16.7484 15.8031 17.1961 16.2508 17.7484 16.2508H18.2484C18.8007 16.2508 19.2484 15.8031 19.2484 15.2508V9.75082C19.2484 9.19854 18.8007 8.75082 18.2484 8.75082Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Volume;
