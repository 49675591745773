const Window = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.55008 3.16637C6.55008 2.89021 6.3262 2.66634 6.05003 2.66637L4.50002 2.66651C3.4875 2.66651 2.66669 3.48733 2.66669 4.49985V11.4998C2.66669 12.5124 3.4875 13.3332 4.50002 13.3332H11.5C12.5126 13.3332 13.3334 12.5124 13.3334 11.4998V9.62856C13.3334 9.35242 13.1095 9.12856 12.8334 9.12856C12.5572 9.12856 12.3334 9.35242 12.3334 9.62856V11.4998C12.3334 11.9601 11.9603 12.3332 11.5 12.3332H4.50002C4.03978 12.3332 3.66669 11.9601 3.66669 11.4998V4.49985C3.66669 4.03961 4.03978 3.66651 4.50002 3.66651L6.05013 3.66637C6.32625 3.66634 6.55008 3.44249 6.55008 3.16637Z"
      fill="currentColor"
    />
    <path
      d="M12.831 6.88095V3.16797H8.79584"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.358 3.63525L8.24398 7.74926"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Window;
