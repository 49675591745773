import { useEffect, useState } from 'react';

export const useCurrentDate = (updateInterval: number = 1000) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, updateInterval);

    return () => {
      clearInterval(interval);
    };
  }, [updateInterval]);

  return date;
};
