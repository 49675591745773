import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { type SwapStatusResponseV2 } from '@chainflip/sdk/swap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { type Integration, integrationManager, isSupportedIntegration } from '../integrations';
import { isSwappingFinished } from '../utils/sdk';

export default function useSwapStatus() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { integration, swapId } = router.query;
  const [isEnabled, setIsEnabled] = useState(true);
  const retainOldQuote = useRef(true);
  const sendingStatusCount = useRef(0);

  const {
    data: status,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['swapStatus', integration, swapId],
    queryFn: async () =>
      (await integrationManager.getStatus(integration as Integration, swapId as string)) ?? null,
    refetchInterval: 5000,
    enabled: isEnabled && isSupportedIntegration(integration) && typeof swapId === 'string',
    structuralSharing: (oldData, newData) => {
      if (!oldData) return newData;
      /* eslint-disable no-param-reassign */

      if (oldData?.integration === 'chainflip' && newData?.integration === 'chainflip') {
        // If egress amount is 0, avoid updating the quote
        // This is an edge case where LPs are rebalancing and there are no orders in the current block
        // The swap would anyways not occur at this rate due to the protocol slippage protection
        if (newData.route.destAmount.eq(0) && !isSwappingFinished(newData)) {
          newData.route = oldData.route;
        }

        // check if the route is actually only a quote
        if (retainOldQuote.current && !isSwappingFinished(newData)) {
          newData.route = oldData.route;
        }

        // for DCA swaps, keep the state as SWAPPING longer so we can show the last chunk execution
        if (
          oldData.integrationData?.state === 'SWAPPING' &&
          newData.integrationData?.state === 'SENDING' &&
          newData.integrationData?.depositChannel?.dcaParams
        ) {
          (newData.integrationData as SwapStatusResponseV2).state =
            sendingStatusCount.current < 1 ? 'SWAPPING' : 'SENDING';
          sendingStatusCount.current += 1;
        } else {
          sendingStatusCount.current = 0;
        }
      }

      retainOldQuote.current = true;

      // chainflip state changes from AWAITING_DEPOSIT to BROADCAST_REQUESTED in one step most times
      // we want to interpolate the states in between to make the experience nicer for the user
      if (
        oldData?.integration === 'chainflip' &&
        newData?.integration === 'chainflip' &&
        newData.integrationData?.state === 'SENDING'
      ) {
        if (
          !oldData.integrationData || // vault contract swaps have no integration data until deposit is witnessed
          oldData?.integrationData?.state === 'RECEIVING'
        ) {
          (newData.integrationData as SwapStatusResponseV2).state = 'SWAPPING';
        }
      }

      return newData;
    },
  });

  const refreshStatus = () => {
    retainOldQuote.current = false;
    refetch();
  };

  useEffect(() => {
    const swapIsFinished =
      (status?.integration !== 'chainflip' && status?.status === 'completed') ||
      (status?.integration === 'chainflip' && status?.integrationData?.state === 'COMPLETED') ||
      status?.status === 'failed';

    setIsEnabled(!swapIsFinished);
  }, [status]);

  // prevent loading state when updating url to include shareable id
  useEffect(() => {
    if (
      status?.shareableId &&
      !queryClient.getQueryData(['swapStatus', integration, status.shareableId])
    ) {
      queryClient.setQueryData(['swapStatus', integration, status.shareableId], status);
    }
  }, [status?.shareableId]);

  return {
    refreshStatus,
    status,
    isLoading: isLoading || !router.isReady,
  };
}
