const Gas = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0359 18.9305C14.0359 19.3744 14.3957 19.7342 14.8395 19.7342C15.2834 19.7342 15.6432 19.3744 15.6432 18.9305H14.0359ZM4.92744 18.9305C4.92744 19.3744 5.28727 19.7342 5.73113 19.7342C6.17499 19.7342 6.53481 19.3744 6.53481 18.9305H4.92744ZM7.87428 6.60737H12.6964V5H7.87428V6.60737ZM14.0359 7.94684V18.9305H15.6432V7.94684H14.0359ZM6.53481 18.9305V7.94684H4.92744V18.9305H6.53481ZM12.6964 6.60737C13.4362 6.60737 14.0359 7.20706 14.0359 7.94684H15.6432C15.6432 6.31935 14.3239 5 12.6964 5V6.60737ZM7.87428 5C6.24679 5 4.92744 6.31935 4.92744 7.94684H6.53481C6.53481 7.20706 7.13451 6.60737 7.87428 6.60737V5Z"
      fill="currentColor"
    />
    <path
      d="M8.94586 9.01842V8.21474C8.50201 8.21474 8.14218 8.57456 8.14218 9.01842H8.94586ZM11.6248 9.01842H12.4285C12.4285 8.57456 12.0687 8.21474 11.6248 8.21474V9.01842ZM11.6248 12.7689V13.5726C12.0687 13.5726 12.4285 13.2128 12.4285 12.7689H11.6248ZM8.94586 12.7689H8.14218C8.14218 13.2128 8.50201 13.5726 8.94586 13.5726V12.7689ZM8.94586 9.8221H11.6248V8.21474H8.94586V9.8221ZM10.8211 9.01842V12.7689H12.4285V9.01842H10.8211ZM9.74955 12.7689V9.01842H8.14218V12.7689H9.74955ZM8.94586 13.5726H11.6248V11.9653H8.94586V13.5726Z"
      fill="currentColor"
    />
    <path
      d="M4.65746 18.3926C4.2136 18.3926 3.85378 18.7525 3.85378 19.1963C3.85378 19.6402 4.2136 20 4.65746 20V18.3926ZM15.909 20C16.3529 20 16.7127 19.6402 16.7127 19.1963C16.7127 18.7525 16.3529 18.3926 15.909 18.3926V20ZM4.65746 20H15.909V18.3926H4.65746V20Z"
      fill="currentColor"
    />
    <path
      d="M15.1053 14.9142C14.6615 14.9142 14.3017 15.274 14.3017 15.7179C14.3017 16.1617 14.6615 16.5216 15.1053 16.5216V14.9142ZM18.3229 8.98392L17.7221 8.44997L18.3229 8.98392ZM20.7961 7.4113C21.0909 7.07956 21.0611 6.57157 20.7293 6.27668C20.3976 5.98179 19.8895 6.01168 19.5946 6.34343L20.7961 7.4113ZM15.1053 16.5216H15.909V14.9142H15.1053V16.5216ZM18.8559 13.5747V9.69583H17.2485V13.5747H18.8559ZM18.9235 9.51784L20.7961 7.4113L19.5946 6.34343L17.7221 8.44997L18.9235 9.51784ZM18.8559 9.69583C18.8559 9.63025 18.88 9.56692 18.9235 9.51784L17.7221 8.44997C17.4171 8.79324 17.2485 9.23655 17.2485 9.69583H18.8559ZM15.909 16.5216C17.5365 16.5216 18.8559 15.2022 18.8559 13.5747H17.2485C17.2485 14.3145 16.6489 14.9142 15.909 14.9142V16.5216Z"
      fill="currentColor"
    />
  </svg>
);

export default Gas;
