const Message = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 12.1666C10.3333 12.1666 12.8333 11 12.8333 7.99998C12.8333 4.99998 10.3333 3.83331 8 3.83331C5.66667 3.83331 3.16667 4.99998 3.16667 7.99998C3.16667 8.68651 3.29759 9.27705 3.52749 9.77951C3.67079 10.0928 3.75117 10.4382 3.68769 10.7767L3.51289 11.709C3.42649 12.1698 3.83018 12.5735 4.29101 12.4871L6.42925 12.0862C6.59733 12.0546 6.76967 12.0574 6.93853 12.0842C7.29 12.1399 7.64693 12.1666 8 12.1666Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33333 8.00002C6.33333 8.18409 6.18409 8.33335 6 8.33335C5.81591 8.33335 5.66667 8.18409 5.66667 8.00002C5.66667 7.81595 5.81591 7.66669 6 7.66669C6.18409 7.66669 6.33333 7.81595 6.33333 8.00002Z"
      stroke="currentColor"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33333 8.00002C8.33333 8.18409 8.18407 8.33335 8 8.33335C7.81593 8.33335 7.66667 8.18409 7.66667 8.00002C7.66667 7.81595 7.81593 7.66669 8 7.66669C8.18407 7.66669 8.33333 7.81595 8.33333 8.00002Z"
      stroke="currentColor"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3333 8.00002C10.3333 8.18409 10.1841 8.33335 10 8.33335C9.81593 8.33335 9.66667 8.18409 9.66667 8.00002C9.66667 7.81595 9.81593 7.66669 10 7.66669C10.1841 7.66669 10.3333 7.81595 10.3333 8.00002Z"
      stroke="currentColor"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Message;
