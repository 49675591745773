const Settings2 = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.74745 9.97516L4.48132 10.0901C5.02306 10.1749 5.40994 10.6711 5.37004 11.2301L5.31598 11.9878C5.30023 12.2085 5.41782 12.4163 5.61257 12.5124L6.15536 12.7798C6.35011 12.876 6.58266 12.84 6.74174 12.6902L7.28714 12.1747C7.68927 11.7945 8.30974 11.7945 8.71234 12.1747L9.25774 12.6902C9.4168 12.8406 9.64887 12.876 9.84414 12.7798L10.3879 12.5119C10.5822 12.4163 10.6993 12.209 10.6835 11.9889L10.6294 11.2301C10.5895 10.6711 10.9764 10.1749 11.5181 10.0901L12.252 9.97516C12.4657 9.94189 12.6379 9.77809 12.6861 9.56222L12.82 8.96189C12.8683 8.74602 12.7827 8.52209 12.6043 8.39749L11.9917 7.96842C11.5397 7.65162 11.4016 7.03302 11.6746 6.54703L12.0447 5.88868C12.1523 5.69698 12.1345 5.45694 11.9995 5.28404L11.6242 4.80236C11.4893 4.62944 11.2646 4.55803 11.0578 4.623L10.3481 4.84532C9.82367 5.00963 9.2646 4.73416 9.063 4.21274L8.79107 3.50768C8.71127 3.30148 8.51654 3.16616 8.29974 3.16669L7.69814 3.1683C7.48134 3.16884 7.28714 3.30524 7.2084 3.51198L6.94327 4.20898C6.7438 4.73362 6.18213 5.01124 5.65614 4.84585L4.91702 4.61388C4.70967 4.54836 4.48394 4.62032 4.34904 4.7943L3.97632 5.27652C3.84142 5.45104 3.82514 5.69161 3.93486 5.88331L4.31334 6.54327C4.59208 7.02976 4.4556 7.65429 4.001 7.97276L3.39574 8.39696C3.21726 8.52209 3.1317 8.74602 3.17999 8.96136L3.31385 9.56169C3.36162 9.77809 3.5338 9.94189 3.74745 9.97516Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.06074 6.93927C9.6464 7.525 9.6464 8.475 9.06074 9.06074C8.475 9.6464 7.525 9.6464 6.93927 9.06074C6.35358 8.475 6.35358 7.525 6.93927 6.93927C7.525 6.35358 8.475 6.35358 9.06074 6.93927Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Settings2;
