import { useMemo } from 'react';
import { BrowserProvider, JsonRpcSigner } from 'ethers';
import { providers } from 'ethers-v5';
import type { Account, Chain, Client, Transport } from 'viem';
import { type Config, useConnectorClient } from 'wagmi';

export type WalletClient = Client<Transport, Chain, Account>;

export function clientToSignerEthersV5(client: WalletClient) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

export function clientToSigner(client: WalletClient) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new BrowserProvider(transport, network);
  const signer = new JsonRpcSigner(provider, account.address);
  return signer;
}

// https://wagmi.sh/react/guides/ethers#usage-1
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId });
  return useMemo(() => (client && client.chain ? clientToSigner(client) : undefined), [client]);
}
