const SleepClock = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.8333 8.00002C12.8333 8.95596 12.5499 9.89044 12.0188 10.6853C11.4877 11.4801 10.7328 12.0996 9.84964 12.4654C8.96646 12.8313 7.99464 12.927 7.05707 12.7405C6.11949 12.554 5.25827 12.0937 4.58232 11.4177C3.90637 10.7417 3.44604 9.88053 3.25954 8.94296C3.07304 8.00538 3.16876 7.03356 3.53458 6.15038C3.90041 5.26721 4.51991 4.51234 5.31475 3.98125C6.10958 3.45016 7.04406 3.16669 8 3.16669M8.16667 5.83335V8.00002L7.16667 8.83335M11.1667 3.16669H12.8333L11.1667 5.50002H12.8333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SleepClock;
