import { useMemo } from 'react';
import { assert } from '@chainflip/utils/assertion';
import { assetConstants, type BaseChainflipAsset } from '@chainflip/utils/chainflip';
import BigNumber from 'bignumber.js';
import { useGqlQuery } from './useGqlQuery';
import { type GetPoolLastPricesQuery } from '../graphql/generated/graphql';
import { getPoolSwapsQuery } from '../queries/pools';
import { formatWithNumeral, TokenAmount } from '../utils';

type Swap = NonNullable<
  GetPoolLastPricesQuery['earliestSwap'] | GetPoolLastPricesQuery['latestSwap']
>['nodes'][number];

export const calculateUsdcPrice = (swap: Swap, baseAsset: BaseChainflipAsset): number => {
  let price;
  let fromAsset;
  let fromAmount;
  let toAmount;

  assert([swap.sourceAsset, swap.destinationAsset].includes(baseAsset), 'Invalid swap');

  if (swap.sourceAsset === 'Usdc' || swap.destinationAsset === 'Usdc') {
    fromAsset = swap.sourceAsset;
    fromAmount = swap.swapInputAmount;
    toAmount = swap.swapOutputAmount;
  } else {
    fromAsset = swap.sourceAsset === baseAsset ? swap.sourceAsset : 'Usdc';
    fromAmount = swap.sourceAsset === baseAsset ? swap.swapInputAmount : swap.intermediateAmount;
    toAmount =
      swap.destinationAsset === baseAsset ? swap.swapOutputAmount : swap.intermediateAmount;
  }

  assert(fromAmount && toAmount, 'Invalid swap');

  if (fromAsset === 'Usdc') {
    price = new BigNumber(fromAmount).dividedBy(toAmount);
  } else {
    price = new BigNumber(toAmount).dividedBy(fromAmount);
  }

  return Number(
    price
      .shiftedBy(assetConstants[baseAsset].decimals - assetConstants.Usdc.decimals)
      .toFixed(assetConstants.Usdc.decimals),
  );
};

export const useComparePoolPrice = ({
  baseAsset,
  endTime,
  startTime,
}: {
  baseAsset: BaseChainflipAsset;
  endTime: Date;
  startTime: Date;
}) => {
  const { data, isLoading } = useGqlQuery(getPoolSwapsQuery, {
    variables: {
      baseAsset,
      latestSwapTimestamp: endTime.toISOString(),
      earliestSwapTimestamp: startTime.toISOString(),
    },
    placeholderData: (previousData) => previousData,
    enabled: baseAsset !== undefined,
  });

  return useMemo(() => {
    const latestSwap = data?.latestSwap?.nodes.at(0);
    const earliestSwap = data?.earliestSwap?.nodes.at(0);

    if (!latestSwap || !earliestSwap) {
      return {
        earliestPrice: undefined,
        latestPrice: undefined,
        diff: undefined,
        percentDiff: undefined,
        isLoading,
      };
    }

    const latestPrice = calculateUsdcPrice(latestSwap, baseAsset);
    const earliestPrice = calculateUsdcPrice(earliestSwap, baseAsset);

    return {
      latestPrice,
      earliestPrice,
      diff: new BigNumber(latestPrice).minus(earliestPrice),
      percentDiff: formatWithNumeral(
        TokenAmount.fromWholeUnits(latestPrice, 2)
          .difference(TokenAmount.fromWholeUnits(earliestPrice, 2))
          .toFixed(2),
      ),
      isLoading,
    };
  }, [data]);
};
