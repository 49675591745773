const NoSearch = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90274 17.2801C8.83648 17.7415 9.88796 18.0008 11 18.0008C12.6628 18.0008 14.1902 17.421 15.3911 16.4526L18.7197 19.7812C19.0126 20.074 19.4874 20.074 19.7803 19.7812C20.0732 19.4883 20.0732 19.0134 19.7803 18.7205L16.4518 15.3919C17.4202 14.1911 18 12.6637 18 11.0008C18 9.88878 17.7407 8.83731 17.2792 7.90357L16.1409 9.0419C16.3729 9.65044 16.5 10.3108 16.5 11.0008C16.5 14.0384 14.0376 16.5008 11 16.5008C10.31 16.5008 9.64962 16.3738 9.04107 16.1417L7.90274 17.2801ZM15.3833 7.67818L16.4518 6.60973C16.1371 6.2195 15.7813 5.86376 15.3911 5.54906L14.3226 6.61752C14.7226 6.92114 15.0797 7.27825 15.3833 7.67818ZM6.61669 14.3235C6.92032 14.7234 7.27743 15.0805 7.67736 15.3841L6.6089 16.4526C6.21868 16.1379 5.86294 15.7821 5.54824 15.3919L6.61669 14.3235ZM12.9589 5.85991C12.3504 5.6279 11.69 5.50082 11 5.50082C7.96243 5.50082 5.5 7.96326 5.5 11.0008C5.5 11.6909 5.62707 12.3512 5.85909 12.9598L4.72076 14.0981C4.25931 13.1643 4 12.1129 4 11.0008C4 7.13483 7.13401 4.00082 11 4.00082C12.112 4.00082 13.1635 4.26013 14.0973 4.72158L12.9589 5.85991Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46966 17.5312C4.17677 17.2383 4.17677 16.7634 4.46966 16.4705L16.4697 4.47049C16.7626 4.1776 17.2374 4.1776 17.5303 4.47049C17.8232 4.76339 17.8232 5.23826 17.5303 5.53115L5.53032 17.5312C5.23743 17.824 4.76255 17.824 4.46966 17.5312Z"
      fill="currentColor"
    />
  </svg>
);

export default NoSearch;
