import type { EthersError } from 'ethers';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

const isEthersError = (error: Error): error is EthersError =>
  Object.hasOwn(error, 'code') && Object.hasOwn(error, 'shortMessage');

export const getEthersCode = (error: Error): string | null =>
  isEthersError(error) ? error.code : null;

export const tryGetEthersError = (error: Error, action = 'Contract interaction'): string => {
  switch (getEthersCode(error)) {
    case 'ACTION_REJECTED':
      return `${action} rejected by user`;
    default:
      return error.message;
  }
};
