import { type Tab, TabSelect } from './TabSelect';

export const timeframeTabOptions = [
  {
    label: '1D',
    value: 1,
  },
  {
    label: '1W',
    value: 7,
  },
  {
    label: '1M',
    value: 30,
  },
  {
    // TODO: Unfake
    // Fake 'All' button until we find a solution for the longer timeframe queries
    label: 'All',
    value: 90,
  },
  // TODO: requires a bunch of work to handle querying more data as the user scrolls
  // through the chart
  // {
  //   label: '1Y',
  //   value: 360,
  // },
];
type TabOption = (typeof timeframeTabOptions)[number];

export const TimeframeTabSelect = ({
  selected,
  setSelected,
}: {
  selected: Tab<number>;
  setSelected: (option: TabOption) => void;
}) => (
  <TabSelect
    tabs={timeframeTabOptions}
    onSelectionChange={(option) => setSelected(option as TabOption)}
    selectedTab={selected}
    pillWidth={28}
    pillHeight={26}
    containerMargin={2}
    btnClasses="text-12"
  />
);
