const Chunks = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M8.66666 4.17731C8.66666 4.45345 8.44276 4.67731 8.16666 4.67731C7.89056 4.67731 7.66666 4.45345 7.66666 4.17731C7.66666 3.90117 7.89056 3.67731 8.16666 3.67731C8.44276 3.67731 8.66666 3.90117 8.66666 4.17731Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66666 8.17731C8.66666 8.45341 8.44276 8.67731 8.16666 8.67731C7.89056 8.67731 7.66666 8.45341 7.66666 8.17731C7.66666 7.90121 7.89056 7.67731 8.16666 7.67731C8.44276 7.67731 8.66666 7.90121 8.66666 8.17731Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6667 4.17731C12.6667 4.45345 12.4428 4.67731 12.1667 4.67731C11.8906 4.67731 11.6667 4.45345 11.6667 4.17731C11.6667 3.90117 11.8906 3.67731 12.1667 3.67731C12.4428 3.67731 12.6667 3.90117 12.6667 4.17731Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6667 8.17731C12.6667 8.45341 12.4428 8.67731 12.1667 8.67731C11.8906 8.67731 11.6667 8.45341 11.6667 8.17731C11.6667 7.90121 11.8906 7.67731 12.1667 7.67731C12.4428 7.67731 12.6667 7.90121 12.6667 8.17731Z"
      stroke="#909090"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66666 4.17731C4.66666 4.45345 4.4428 4.67731 4.16666 4.67731C3.89052 4.67731 3.66666 4.45345 3.66666 4.17731C3.66666 3.90117 3.89052 3.67731 4.16666 3.67731C4.4428 3.67731 4.66666 3.90117 4.66666 4.17731Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66666 8.17731C4.66666 8.45341 4.4428 8.67731 4.16666 8.67731C3.89052 8.67731 3.66666 8.45341 3.66666 8.17731C3.66666 7.90121 3.89052 7.67731 4.16666 7.67731C4.4428 7.67731 4.66666 7.90121 4.66666 8.17731Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66666 12.1773C8.66666 12.4534 8.44276 12.6773 8.16666 12.6773C7.89056 12.6773 7.66666 12.4534 7.66666 12.1773C7.66666 11.9012 7.89056 11.6773 8.16666 11.6773C8.44276 11.6773 8.66666 11.9012 8.66666 12.1773Z"
      stroke="#909090"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6667 12.1773C12.6667 12.4534 12.4428 12.6773 12.1667 12.6773C11.8906 12.6773 11.6667 12.4534 11.6667 12.1773C11.6667 11.9012 11.8906 11.6773 12.1667 11.6773C12.4428 11.6773 12.6667 11.9012 12.6667 12.1773Z"
      stroke="#909090"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66666 12.1773C4.66666 12.4534 4.4428 12.6773 4.16666 12.6773C3.89052 12.6773 3.66666 12.4534 3.66666 12.1773C3.66666 11.9012 3.89052 11.6773 4.16666 11.6773C4.4428 11.6773 4.66666 11.9012 4.66666 12.1773Z"
      stroke="#909090"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chunks;
