const ChartUpward = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M4.74828 6.74925C4.74828 5.64468 5.64371 4.74925 6.74828 4.74925H17.2483C18.3529 4.74925 19.2483 5.64468 19.2483 6.74925V17.2493C19.2483 18.3539 18.3529 19.2493 17.2483 19.2493H6.74828C5.64371 19.2493 4.74828 18.3539 4.74828 17.2493V6.74925Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.74847 14.4993L10.9985 11.9993L12.9985 14.4993L15.2485 9.99925"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChartUpward;
