import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import { erc20Abi } from 'viem';
import { useAccount, useBalance, usePublicClient, useReadContracts } from 'wagmi';
import {
  type ChainData,
  parseEvmChainId,
  type ChainId,
  isSolanaChain,
} from '@/shared/assets/chains';
import { NATIVE_TOKEN_ADDRESS } from '@/shared/assets/tokens/constants';
import { TokenAmount, getChainflipToken } from '@/shared/utils';
import { useTokens } from './useTokens';

interface BalancesData {
  balances: Partial<Record<string, TokenAmount>>;
  isLoading: boolean;
}

type Balances = Partial<Record<ChainId, Partial<Record<string, TokenAmount>>>> & {
  evmAddress?: string;
  solAddress?: string;
};

type ContextValue = [Balances, React.Dispatch<React.SetStateAction<Balances>>];

const Context = createContext<ContextValue | null>(null);

export const ChainBalancesProvider = ({ children }: { children: React.ReactNode }) => {
  const [balances, setBalances] = useState<Balances>({});

  const ctx = useMemo(() => [balances, setBalances] as ContextValue, [balances]);

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export const useChainBalances = (chain: ChainData | undefined): BalancesData => {
  const ctx = useContext(Context);

  if (ctx === null) {
    throw new Error('useChainBalances must be used within a ChainBalancesProvider');
  }

  const [balances, setBalances] = ctx;

  const { address: connectedAddress } = useAccount();
  const solanaWallet = useWallet();
  const { connection: solanaConnection } = useConnection();

  const { tokens, isLoading: tokensLoading } = useTokens(chain?.id);
  const evmChainId = parseEvmChainId(chain?.id);
  const publicClient = usePublicClient({ chainId: evmChainId });

  const { data: solBalance, isLoading: solBalanceLoading } = useQuery({
    queryKey: ['SOL_BALANCE', solanaWallet.publicKey],
    enabled: solanaWallet.connected,
    queryFn: async () => {
      const balance = await solanaConnection.getBalance(solanaWallet.publicKey!);
      return TokenAmount.fromAsset(balance, 'Sol');
    },
  });

  const solUsdcAddress = getChainflipToken('SolUsdc').address;

  const { data: solUsdcBalance, isLoading: solUsdcBalanceLoading } = useQuery({
    queryKey: ['SOL_USDC_BALANCE', solanaWallet.publicKey],
    enabled: solanaWallet.connected,
    queryFn: async () => {
      const accounts = await solanaConnection.getTokenAccountsByOwner(solanaWallet.publicKey!, {
        mint: new PublicKey(solUsdcAddress),
      });
      const account = accounts.value.at(0);
      if (!account) return undefined;
      const balance = await solanaConnection.getTokenAccountBalance(account.pubkey);
      return TokenAmount.fromAsset(balance.value.amount, 'SolUsdc');
    },
  });

  useEffect(() => {
    const chainId = chain?.id;
    const isSolana = isSolanaChain(chainId);

    if (!isSolana) return;

    setBalances((current) => {
      const solAddress = solanaWallet.publicKey?.toString();

      return {
        ...current,
        solAddress,
        [chainId]: { [NATIVE_TOKEN_ADDRESS]: solBalance, [solUsdcAddress]: solUsdcBalance },
      };
    });
  }, [solBalance, solUsdcBalance, solanaWallet.publicKey, chain?.id]);

  const calls = useMemo(() => {
    if (!connectedAddress || !publicClient?.chain || evmChainId === undefined) {
      return undefined;
    }

    const { id: chainId } = publicClient.chain;
    if (evmChainId !== chainId) return undefined;

    return tokens
      .filter((token) => token.address !== NATIVE_TOKEN_ADDRESS)
      .map(
        (token) =>
          ({
            chainId: evmChainId,
            abi: erc20Abi,
            address: token.address as `0x${string}`,
            functionName: 'balanceOf',
            args: [connectedAddress],
            token,
          }) as const,
      );
  }, [tokens, publicClient, connectedAddress, evmChainId]);

  const { isLoading: tokenBalancesLoading, data: tokenBalancesData } = useReadContracts({
    contracts: calls,
    allowFailure: true,
    query: {
      refetchInterval: 10_000,
    },
  });

  const { isLoading: nativeBalanceLoading, data: nativeBalance } = useBalance({
    address: connectedAddress,
    chainId: evmChainId,
    query: {
      refetchInterval: 10_000,
    },
  });

  useEffect(() => {
    if (!chain?.id || !tokenBalancesData || !nativeBalance) return;

    const balanceEntries = [
      [NATIVE_TOKEN_ADDRESS, new TokenAmount(String(nativeBalance.value), nativeBalance.decimals)],
      ...tokenBalancesData.map((result, index) => [
        calls?.[index].address,
        new TokenAmount(String(result.result ?? 0), calls?.[index].token.decimals),
      ]),
    ];
    if (!balanceEntries.length) return;

    setBalances((current) => {
      if (current.evmAddress !== connectedAddress) {
        return { evmAddress: connectedAddress, [chain.id]: Object.fromEntries(balanceEntries) };
      }

      return { ...current, [chain.id]: Object.fromEntries(balanceEntries) };
    });
  }, [tokenBalancesData, nativeBalance, tokens, chain?.id]);

  return useMemo(
    () => ({
      balances: (chain?.id && balances[chain.id]) ?? {},
      isLoading:
        tokensLoading ||
        tokenBalancesLoading ||
        nativeBalanceLoading ||
        solBalanceLoading ||
        solUsdcBalanceLoading,
    }),
    [
      balances,
      tokenBalancesLoading,
      nativeBalanceLoading,
      tokensLoading,
      solBalanceLoading,
      chain?.id,
    ],
  );
};
