import { useMemo, useState } from 'react';
import { differenceInDays, endOfDay, startOfDay, subDays } from 'date-fns';

export default function useTimeframeSelection<T extends { value: number }>(
  options: T[],
  defaultSelection: T = options[options.length ? options.length - 1 : 0],
) {
  const [selectedTimeframe, setSelectedTimeframe] = useState(defaultSelection);
  const [chartStartDate, chartEndDate] = useMemo(
    () => [startOfDay(subDays(new Date(), selectedTimeframe.value)), endOfDay(new Date())],
    [selectedTimeframe],
  );
  const daysBack = differenceInDays(chartEndDate, chartStartDate);

  return {
    selectedTimeframe,
    setSelectedTimeframe,
    chartStartDate,
    chartEndDate,
    daysBack,
    isDaily: daysBack === 1,
  };
}
