const Scale = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7966 10.75H19.2034M4.79661 10.75H9.20339M12 16.75V5.75M12 5.75V4.75M12 5.75H4.75M12 5.75H19.25M17 5.75L19.0738 10.3585C19.19 10.6166 19.25 10.8963 19.25 11.1793V11.25C19.25 12.3546 18.3546 13.25 17.25 13.25H16.75C15.6454 13.25 14.75 12.3546 14.75 11.25V11.1793C14.75 10.8963 14.81 10.6166 14.9262 10.3585L17 5.75ZM7 5.75L9.07384 10.3585C9.18996 10.6166 9.25 10.8963 9.25 11.1793V11.25C9.25 12.3546 8.35457 13.25 7.25 13.25H6.75C5.64543 13.25 4.75 12.3546 4.75 11.25V11.1793C4.75 10.8963 4.81004 10.6166 4.92616 10.3585L7 5.75ZM15.25 19.25V18.75C15.25 17.6454 14.3546 16.75 13.25 16.75H10.75C9.64543 16.75 8.75 17.6454 8.75 18.75V19.25H15.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Scale;
