import classNames from 'classnames';
import loading from '../animations/loading.json';
import Lottie from '../utils/Lottie';

export default function LoadingSpinner({ className }: { className?: string }) {
  return (
    <Lottie
      as="div"
      animationData={loading}
      autoplay
      loop
      className={classNames('h-[22px] w-[22px] stroke-white', className)}
    />
  );
}
