type Props = React.SVGProps<SVGSVGElement>;

const EyeHideIcon = (props: Props): JSX.Element => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2381_839)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8667 6.87727C12.8667 6.87727 12.8667 6.8106 12.8 6.8106L11.9333 7.27727C12.0667 7.67727 12.1333 7.94393 12.1333 8.0106C12.1333 8.07727 12.0667 8.34393 11.9333 8.74393C11.8 9.14393 11.5333 9.6106 11.2 10.0106C10.5333 10.8773 9.46667 11.6773 7.8 11.6773C6.6 11.6773 5.66667 11.2773 5 10.6773L4.06667 11.1439C4.93333 12.0106 6.13333 12.6773 7.86667 12.6773C9.86667 12.6773 11.2 11.6773 12.0667 10.6773C12.4667 10.1439 12.7333 9.6106 12.9333 9.14393C13.0667 8.67727 13.2 8.27727 13.2 8.0106C13.2 7.74393 13.0667 7.34393 12.8667 6.87727ZM7.86667 3.34393C5.86667 3.34393 4.53333 4.34393 3.66667 5.34393C3.26667 5.87727 3 6.4106 2.8 6.87727C2.6 7.34393 2.53333 7.74393 2.53333 8.0106C2.53333 8.27727 2.6 8.67727 2.8 9.14393C2.8 9.14393 2.8 9.2106 2.86667 9.2106L3.73333 8.74393C3.53333 8.34393 3.53333 8.07727 3.53333 8.0106C3.53333 7.94393 3.6 7.67727 3.73333 7.27727C3.86667 6.87727 4.06667 6.4106 4.46667 6.0106C5.13333 5.14393 6.2 4.34393 7.86667 4.34393C9.06667 4.34393 9.93333 4.74393 10.6 5.34393L11.5333 4.87727C10.7333 4.0106 9.53333 3.34393 7.86667 3.34393Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.93334 9.81062C7.2 9.94395 7.46667 10.0106 7.86667 10.0106C8.86667 10.0106 9.66667 9.27729 9.86667 8.34395L6.93334 9.81062ZM7.86667 6.01062C6.86667 6.01062 6 6.74395 5.86667 7.67729L8.8 6.21062C8.46667 6.07729 8.2 6.01062 7.86667 6.01062Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6667 5.47727C13.6667 5.6106 13.5333 5.74394 13.4 5.8106L12.6 6.2106L11.6667 6.67727L9.8 7.6106L8.8 8.07727L7.26666 8.8106L6.33333 9.34394L4.6 10.2106L3.66666 10.6773L2.73333 11.1439C2.46666 11.2773 2.2 11.1439 2.06666 10.9439C1.93333 10.6773 2.06666 10.4106 2.26666 10.2773L3.06666 9.87727L4 9.34394L5.86666 8.4106L6.8 7.94394L8.33333 7.14394L9.33333 6.67727L11.0667 5.8106L12 5.34394L12.9333 4.87727C13.0667 4.8106 13.2667 4.8106 13.4 4.87727C13.4667 4.94394 13.6 5.0106 13.6667 5.07727C13.6667 5.2106 13.6667 5.34394 13.6667 5.47727Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2381_839">
        <rect
          width="11.6667"
          height="9.33333"
          fill="currentColor"
          transform="translate(2 3.34393)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EyeHideIcon;
