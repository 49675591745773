import classNames from 'classnames';
import { Tooltip, type TooltipProps } from './molecules/Tooltip';

type QuestionMarkTooltipProps = Omit<TooltipProps, 'children'> & {
  className?: string;
};

export default function QuestionMarkTooltip(props: QuestionMarkTooltipProps) {
  if (!props.content) return null;
  const { className, ...rest } = props;

  return (
    <Tooltip {...rest}>
      <span
        className={classNames(
          'flex h-4 w-4 items-center justify-center rounded-full border-[0.635px] border-cf-gray-5 bg-cf-gray-4 font-aeonikMedium text-10 text-cf-light-2',
          className,
        )}
      >
        ?
      </span>
    </Tooltip>
  );
}
