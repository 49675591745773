const Slice = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M11.9984 19.2508C16.0024 19.2508 19.2484 16.0049 19.2484 12.0008C19.2484 7.99676 16.0024 4.75082 11.9984 4.75082C7.99429 4.75082 4.74835 7.99676 4.74835 12.0008C4.74835 16.0049 7.99429 19.2508 11.9984 19.2508Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7484 5.00082V10.2508C11.7484 11.3554 12.6438 12.2508 13.7484 12.2508H18.9984"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Slice;
