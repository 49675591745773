import { useState } from 'react';
import className from 'classnames';

export type Tab<T> = {
  label: string;
  value?: T;
  activeClasses?: {
    btn?: string;
    pill?: string;
  };
};

export const TabSelect = <T,>({
  tabs,
  selectedTab,
  onSelectionChange,
  btnClasses,
  pillWidth = 140,
  pillHeight = 34,
  containerMargin = 5,
}: {
  tabs: readonly Tab<T>[];
  selectedTab: Tab<T>;
  onSelectionChange: (option: Tab<T>) => void;
  btnClasses?: string;
  pillWidth?: number;
  pillHeight?: number;
  containerMargin?: number;
}) => {
  const [offsetLeft, setOffsetLeft] = useState<number>();

  const pillClasses = selectedTab.activeClasses?.pill;

  return (
    <div
      className="relative z-0 flex h-fit rounded-md border border-cf-gray-4 bg-cf-gray-3-5"
      style={{ padding: `${containerMargin}px` }}
    >
      {/* only render selection pill once we know the offset to prevent animating initial position */}
      {offsetLeft !== undefined && (
        <div
          className={className(
            'pointer-events-none absolute z-0 border border-cf-gray-5 bg-cf-gray-4 transition ease-in-out',
            pillClasses,
          )}
          style={{
            width: `${pillWidth}px`,
            height: `${pillHeight}px`,
            // offset within parent - padding of parent
            transform: `translateX(${offsetLeft - containerMargin}px)`,
            borderRadius: `${6 - containerMargin}px`,
          }}
        />
      )}
      {tabs.map((tab) => {
        const isSelected = tab.label === selectedTab.label;
        const activeBtnClasses = selectedTab.activeClasses?.btn;
        return (
          <button
            key={tab.label}
            type="button"
            className={className(
              'z-10 rounded-[4px] text-center font-aeonikMedium transition ease-in',
              isSelected && !activeBtnClasses && 'text-white',
              (isSelected && activeBtnClasses) || 'text-cf-light-2 hover:text-white',
              btnClasses,
            )}
            onClick={!isSelected ? () => onSelectionChange(tab) : undefined}
            style={{
              width: `${pillWidth}px`,
              height: `${pillHeight}px`,
            }}
            ref={(element) => {
              if (isSelected && element) setOffsetLeft(element.offsetLeft);
            }}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
};
