interface DoubleArrowIconProps extends React.SVGProps<SVGSVGElement> {
  activeColor: 'white';
  direction?: 'ASC' | 'DESC';
}

const DoubleArrowIcon = ({
  activeColor,
  direction,
  ...props
}: DoubleArrowIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.25 10.5L12 7L8.75 10.5"
      stroke={direction === 'ASC' ? activeColor : 'currentColor'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.25 14L12 17.5L8.75 14"
      stroke={direction === 'DESC' ? activeColor : 'currentColor'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DoubleArrowIcon;
