const DotsHorizontal = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.66666 8.00001C8.66666 8.36821 8.36819 8.66668 7.99999 8.66668C7.63179 8.66668 7.33333 8.36821 7.33333 8.00001C7.33333 7.63181 7.63179 7.33334 7.99999 7.33334C8.36819 7.33334 8.66666 7.63181 8.66666 8.00001Z"
      fill="currentColor"
    />
    <path
      d="M6.00001 8.00001C6.00001 8.36821 5.70153 8.66668 5.33334 8.66668C4.96515 8.66668 4.66667 8.36821 4.66667 8.00001C4.66667 7.63181 4.96515 7.33334 5.33334 7.33334C5.70153 7.33334 6.00001 7.63181 6.00001 8.00001Z"
      fill="currentColor"
    />
    <path
      d="M11.3333 8.00001C11.3333 8.36821 11.0349 8.66668 10.6667 8.66668C10.2985 8.66668 10 8.36821 10 8.00001C10 7.63181 10.2985 7.33334 10.6667 7.33334C11.0349 7.33334 11.3333 7.63181 11.3333 8.00001Z"
      fill="currentColor"
    />
  </svg>
);

export default DotsHorizontal;
