import { type ChangeEvent, useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import { type Token } from '../assets/tokens';
import { removeCommas, TokenAmount } from '../utils';
import { decimalRegex } from '../utils/regex';

function validateInputChange(_value: string, token: Token | undefined): string | null {
  const value = removeCommas(_value.replace(/,$/, '.')); // replace trailing comma with period for eu dummies and then remove all commas.
  if (value === '.') {
    return '0.';
  }
  const onlyDecimals = value.match(/^(\.)(\d+)$/); // match a period followed by digits (backspace case) eg: .1234
  if (onlyDecimals) {
    const [, , decimals] = onlyDecimals;
    return `.${decimals}`;
  }

  if (/^0\d/.test(value)) {
    return value.slice(1);
  }

  if (!decimalRegex(token?.decimals).test(value)) return null;

  return value;
}

function useValidatedTokenAmountInput({
  token,
  initialValue,
}: {
  token?: Token | undefined;
  initialValue?: string;
}) {
  const [value, _setValue] = useState(initialValue ?? '');

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = validateInputChange(event.target.value, token);
      if (newValue != null) {
        _setValue(newValue);
      }
    },
    [token, value],
  );

  const setValue = useCallback(
    (newValue: BigNumber.Value, capped?: boolean) => {
      if (newValue === '') {
        _setValue('');
        return;
      }

      let cleaned = newValue;
      if (typeof cleaned === 'string') {
        cleaned = removeCommas(cleaned);
      }

      const nearestValidAmount = token
        ? BigNumber(cleaned).decimalPlaces(token.decimals)
        : BigNumber(cleaned);

      if (capped) {
        _setValue(
          TokenAmount.fromWholeUnits(nearestValidAmount, token?.decimals).toCapped(undefined, true),
        );
        return;
      }
      _setValue(
        TokenAmount.fromWholeUnits(nearestValidAmount, token?.decimals).toFormatted(
          undefined,
          token?.decimals,
          'ROUND_DOWN',
          true,
        ),
      );
    },
    [_setValue, token],
  );

  return { onChange: onInputChange, value, setValue };
}

export default useValidatedTokenAmountInput;
